import {
  Button,
  Text,
  VStack,
  SimpleGrid,
  HStack,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { CurrencyFragment, useGetCurrenciesQuery } from 'client';
import CurrencyIcon from 'components/CurrencyIcon';
import { useWallet } from 'hooks';
import { useCallback, useMemo } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { ColorMix, TextStyle } from 'theme/types';

const Currency = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currency, changeCurrency } = useWallet();

  const [{ data }] = useGetCurrenciesQuery();

  const fiatCurrencies = useMemo(
    () => data?.currencies.filter((i) => i.isFiat),
    [data?.currencies]
  );

  const cryptoCurrencies = useMemo(
    () => data?.currencies.filter((i) => !i.isFiat),
    [data?.currencies]
  );

  const renderItem = useCallback(
    (item: CurrencyFragment) => {
      return (
        <HStack
          key={item.id}
          aria-current={item.id === currency ? 'page' : undefined}
          onClick={() => {
            changeCurrency(item.id);
            onClose();
          }}
          h="48px"
          rounded={'md'}
          p="2"
          _hover={{
            bg: 'primary.50',
            color: 'primary.500',
          }}
          _activeLink={{
            bg: 'primary.50',
            color: 'primary.500',
          }}>
          <CurrencyIcon id={item.id} boxSize="32px" />
          <VStack alignItems="flex-start" ml="2" spacing="-1">
            <Text textStyle={TextStyle.ParagraphSmall}>{item.name}</Text>
            <Text textStyle={TextStyle.ParagraphXSmall}>{item.id.toUpperCase()}</Text>
          </VStack>
        </HStack>
      );
    },
    [changeCurrency, currency, onClose]
  );

  return (
    <>
      <Button
        disabled={!data}
        onClick={onOpen}
        size="sm"
        px="2"
        rightIcon={<HiOutlineChevronDown />}
        color={ColorMix.textSub}
        leftIcon={<CurrencyIcon boxSize="5" id={currency} />}
        borderColor={ColorMix.border}
        textStyle={TextStyle.ParagraphSmall}
        variant="outline">
        <Text display={['none', 'flex']}>{currency.toUpperCase()}</Text>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody py="6">
            <Text textStyle={TextStyle.Overline} fontWeight="semibold">
              Fiat
            </Text>
            <SimpleGrid mt="4" columns={[2, 3]} spacing={[2, 3]}>
              {fiatCurrencies?.map(renderItem)}
            </SimpleGrid>
            <Text textStyle={TextStyle.Overline} mt="6" fontWeight="semibold">
              Crypto
            </Text>
            <SimpleGrid mt="4" columns={[2, 3]} spacing={[2, 3]}>
              {cryptoCurrencies?.map(renderItem)}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Currency;
