import { HStack, Divider, Text } from '@chakra-ui/react';
import { ColorMix, TextStyle } from 'theme/types';
import Currency from './Currency';
import UserProfile from './UserProfile';
import navs from 'constants/navs';
import { useMemo } from 'react';
import { NavProps } from './types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation('nav');

  const navLabel = useMemo(() => {
    let picked: NavProps | null = null;
    const length = navs.length;
    for (let i = 0; i < length; i++) {
      const item = navs[i];
      if (item.pathname === pathname) {
        picked = item;
        break;
      } else if (item.childs) {
        const childLength = item.childs.length;
        for (let y = 0; y < childLength; y++) {
          const child = item.childs[y];
          if (child.pathname === pathname) {
            picked = child;
            break;
          }
        }
      }
    }
    if (picked) {
      return picked?.label;
    } else {
      if (pathname.includes('/app/')) {
        return 'app';
      } else {
        return undefined;
      }
    }
  }, [pathname]);

  return (
    <HStack
      h={['60px', '70px', '80px']}
      px={'6'}
      justify="space-between"
      bgColor={ColorMix.bgItem}
      borderBottomWidth={1}
      borderBottomColor={ColorMix.border}>
      <Text textStyle={TextStyle.H5} fontWeight="semibold">
        {navLabel ? t(navLabel) : ''}
      </Text>
      <HStack justify={'flex-end'} spacing="3">
        <Currency />
        <Divider orientation="vertical" w="0.5" h="10" />
        <UserProfile />
      </HStack>
    </HStack>
  );
};
export default Header;
