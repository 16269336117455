import { Flex } from '@chakra-ui/react';
import { useApp } from 'hooks';
import { Navigate, Outlet } from 'react-router-dom';
import { ColorMix } from 'theme/types';
import Header from './Header';
import MobileTopBar from './MobileTopBar';
import Sidebar from './Sidebar';

const SidebarLayout = () => {
  const { isAuthenticated } = useApp();

  if (!isAuthenticated) {
    return <Navigate to={'/auth/login'} />;
  }

  return (
    <Flex flexDirection="column">
      <MobileTopBar />
      <Flex flex="1" overflow="hidden" minH="100vh">
        <Sidebar display={{ base: 'none', lg: 'flex' }} />
        <Flex flex="1" bg={ColorMix.bgContent} flexDirection="column">
          <Header />
          <Outlet />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SidebarLayout;
