import { createClient, configureChains, chain, Chain } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { SafeConnector } from '@gnosis.pm/safe-apps-wagmi';
import { MagicConnector } from '@everipedia/wagmi-magic-connector';

const avalancheChain: Chain = {
  id: 43114,
  name: 'Avalanche',
  network: 'avalanche',
  nativeCurrency: {
    decimals: 18,
    name: 'Avalanche',
    symbol: 'AVAX',
  },
  rpcUrls: {
    default: 'https://api.avax.network/ext/bc/C/rpc',
  },
  blockExplorers: {
    default: { name: 'SnowTrace', url: 'https://snowtrace.io' },
  },
  testnet: false,
};

const binanceChain: Chain = {
  id: 56,
  name: 'Binance',
  network: 'bsc',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://rpc.ankr.com/bsc',
  },
  blockExplorers: {
    default: { name: 'BSC Scan', url: 'https://www.bscscan.com' },
  },
  testnet: false,
};

const binanceTestnetChain: Chain = {
  id: 97,
  name: 'Binance Testnet',
  network: 'bsc-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  },
  blockExplorers: {
    default: { name: 'BSC Scan Test', url: 'https://testnet.bscscan.com' },
  },
  testnet: false,
};

const moonbeamChain: Chain = {
  id: 1284,
  name: 'Moonbeam',
  network: 'moonbeam',
  nativeCurrency: {
    decimals: 18,
    name: 'Moonbeam',
    symbol: 'GLMR',
  },
  rpcUrls: {
    default: 'https://rpc.ankr.com/moonbeam',
  },
  blockExplorers: {
    default: { name: 'Moonscan', url: 'https://moonscan.io' },
  },
  testnet: false,
};

const fantomChain: Chain = {
  id: 250,
  name: 'Fantom',
  network: 'fantom',
  nativeCurrency: {
    decimals: 18,
    name: 'Fantom',
    symbol: 'FTM',
  },
  rpcUrls: {
    default: 'https://rpc.ankr.com/fantom',
  },
  blockExplorers: {
    default: { name: 'Ftm Scan', url: 'https://ftmscan.com' },
  },
  testnet: false,
};

const polygonMumbaiChain: Chain = {
  id: 80001,
  name: 'Polygon mumbai',
  network: 'folygon-mumbai',
  nativeCurrency: {
    decimals: 18,
    name: 'Matic',
    symbol: 'MATIC',
  },
  rpcUrls: {
    default: 'https://matic-mumbai.chainstacklabs.com',
  },
  blockExplorers: {
    default: { name: 'Matic Mumbai Scan', url: 'https://ftmscan.com' },
  },
  testnet: true,
};

const { chains, provider } = configureChains(
  [
    chain.mainnet,
    chain.polygon,
    chain.arbitrum,
    avalancheChain,
    binanceChain,
    binanceTestnetChain,
    moonbeamChain,
    fantomChain,
    chain.polygonMumbai,
  ],
  [
    publicProvider(),
    jsonRpcProvider({
      rpc: (chain) => {
        switch (chain.id) {
          case avalancheChain.id:
          case binanceChain.id:
          case moonbeamChain.id:
          case fantomChain.id:
          case binanceTestnetChain.id:
          case polygonMumbaiChain.id:
            return { http: chain.rpcUrls.default };
          default:
            return null;
        }
      },
    }),
  ]
);

export const client = createClient({
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new CoinbaseWalletConnector({
      options: {
        appName: 'Paywong',
      },
      chains,
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
    // @ts-ignore
    new SafeConnector({ chains }),
    // @ts-ignore
    new MagicConnector({
      chains,
      options: {
        apiKey: process.env.REACT_APP_MAGIC_API_KEY || '',
      },
    }),
  ],
  provider,
  autoConnect: true,
});
