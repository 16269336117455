import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import Logo from 'components/Logo';
import NotFoundLogo from 'components/NotFoundLogo';

const PageNotFoundView = () => {
  const navigate = useNavigate();

  return (
    <Box p={'6'} minH="100vh" display={'flex'} flexDirection="column">
      <Logo w={'48'} />
      <Flex
        flex={1}
        justifyContent={'space-around'}
        alignItems="center"
        flexDirection={{ base: 'column', md: 'row' }}>
        <VStack spacing={6}>
          <Text fontSize={'9xl'} fontWeight={'bold'}>
            404
          </Text>
          <Text fontSize={'4xl'}>Page not found</Text>
          <Button variant={'ghost'} onClick={() => navigate('/', { replace: true })}>
            Go Dashboard
          </Button>
        </VStack>
        <NotFoundLogo w={'md'} p="6" />
      </Flex>
    </Box>
  );
};

export default PageNotFoundView;
