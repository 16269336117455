import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { HiOutlineMenu } from 'react-icons/hi';
import { useMobileMenuState } from 'hooks';
import { useNavigate } from 'react-router-dom';

import Logo from '../../Logo';
import Sidebar from './Sidebar';

const MobileTopBar = () => {
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useMobileMenuState();

  return (
    <Flex
      align="center"
      justify="space-between"
      py="2"
      px="4"
      display={{ base: 'flex', lg: 'none' }}
      borderBottomWidth="1px">
      <Box cursor="pointer" onClick={() => navigate('/')}>
        <Logo h="6" />
      </Box>
      <IconButton
        onClick={onOpen}
        variant="unstyled"
        display="flex"
        cursor="pointer"
        aria-label="Menu"
        icon={<HiOutlineMenu fontSize="1.5rem" />}
      />
      <Drawer
        size="xs"
        placement="left"
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent shadow="none" position="relative" maxW="64">
          <Sidebar width="full" height="full" bg="inherit" border="0" />
          <DrawerCloseButton
            bg="blue.500"
            _hover={{ bg: 'blue.600' }}
            _active={{ bg: 'blue.700' }}
            rounded="0"
            position="absolute"
            color="white"
            right="-8"
            top="0"
          />
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default MobileTopBar;
