export const contacts: Record<
  number,
  {
    payNow: {
      address: string;
      startBlock: number;
      apiKey: string;
    };
  }
> = {
  97: {
    payNow: {
      address: '0x38AB4ac9135d04Bf37E5d97eA554a1D892453E01',
      startBlock: 27824500,
      apiKey: 'q4pOdJun5.8f6d2456-3d19-48a0-84eb-328a68868021',
    },
  },
  80001: {
    payNow: {
      address: '0xbe09F983E0AC7712b1749F83Dc91F8d0aD68473c',
      startBlock: 27974083,
      apiKey: 'pwjUq91NZ.08b24b52-8fc3-4345-a8de-edd6d542ecc4',
    },
  },
};

export const DEFAULT_CHAIN_ID = 1;
export const DEFAULT_LIMIT = 20;
