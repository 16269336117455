import { NavProps } from 'components/layouts/SidebarLayout/types';
import {
  HiOutlineEye,
  HiOutlineCurrencyDollar,
  HiOutlineClipboardList,
  HiOutlineUsers,
  HiOutlineInformationCircle,
  HiOutlineDocumentText,
  HiOutlineCog,
} from 'react-icons/hi';

const navs: Array<NavProps> = [
  {
    label: 'overview',
    icon: HiOutlineEye,
    pathname: '/',
  },
  {
    label: 'wallet',
    icon: HiOutlineCurrencyDollar,
    pathname: '/wallet',
  },
  {
    label: 'payments',
    icon: HiOutlineClipboardList,
    childs: [
      {
        label: 'in-store',
        pathname: '/payments',
      },
      {
        label: 'apps',
        pathname: '/payments/apps',
      },
    ],
  },
  {
    label: 'invoices',
    icon: HiOutlineDocumentText,
    childs: [
      {
        label: 'draft',
        pathname: '/invoices/draft',
      },
      {
        label: 'sent',
        pathname: '/invoices/sent',
      },
      {
        label: 'received',
        pathname: '/invoices/received',
      },
    ],
  },
  {
    label: 'contacts',
    icon: HiOutlineUsers,
    pathname: '/contacts',
  },
  {
    label: 'settings',
    icon: HiOutlineCog,
    pathname: '/settings',
  },
  // {
  //   label: 'apps',
  //   icon: HiOutlineCog,
  //   pathname: '/apps',
  // },
  {
    label: 'documentation',
    icon: HiOutlineInformationCircle,
    pathname: 'https://docs.paywong.com',
  },
];

export default navs;
