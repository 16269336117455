import { chakra, HTMLChakraProps, useColorModeValue } from '@chakra-ui/react';

interface LogoProps extends HTMLChakraProps<'svg'> {}
const Logo = ({ ...rest }: LogoProps) => {
  const color = useColorModeValue('#170000', 'white');
  return (
    <chakra.svg viewBox="0 0 146 38" fill="none" {...rest}>
      <path
        d="M50.1746 27.4636V20.8781C51.1195 21.8913 52.5367 22.559 54.0957 22.559C57.6151 22.559 60.1426 20.0261 60.1426 16.6182C60.1426 13.3946 57.497 10.7235 54.0012 10.7235C50.5053 10.7235 47.8362 13.2564 47.8362 16.6182V27.4636H50.1746ZM54.0012 20.4176C51.8281 20.4176 50.1746 18.6906 50.1746 16.6413C50.1746 14.5689 51.8281 12.888 54.0012 12.888C56.1507 12.888 57.8041 14.5689 57.8041 16.6413C57.8041 18.6906 56.1507 20.4176 54.0012 20.4176Z"
        fill={color}
      />
      <path
        d="M66.681 22.559C68.5707 22.559 70.106 21.684 70.98 20.3025V22.2827H73.2004V16.6182C73.2004 13.2564 70.5312 10.7235 67.0354 10.7235C63.5159 10.7235 60.8704 13.3946 60.8704 16.6182C60.8704 19.888 63.3269 22.559 66.681 22.559ZM67.0354 20.3946C64.8859 20.3946 63.2088 18.6906 63.2088 16.6413C63.2088 14.5689 64.8859 12.865 67.0354 12.865C69.2085 12.865 70.8619 14.5689 70.8619 16.6413C70.8619 18.6906 69.2085 20.3946 67.0354 20.3946Z"
        fill={color}
      />
      <path
        d="M80.6601 27.763C84.4157 27.763 86.6833 25.161 86.6833 21.3847V10.9998H84.3213V16.5722C84.3213 18.6446 82.9985 20.3025 80.9671 20.3025C78.8649 20.3025 77.7311 18.8518 77.7311 16.7334V10.9998H75.4163V16.8485C75.4163 20.1643 77.1406 22.4439 80.6837 22.4439C82.4316 22.4439 83.6599 21.684 84.3449 20.4176V21.4077C84.3449 23.8485 83.0694 25.6446 80.6128 25.6446C79.0302 25.6446 77.7311 24.9307 77.0697 23.7104L75.1328 24.7465C76.1721 26.7498 78.2035 27.763 80.6601 27.763Z"
        fill={color}
      />
      <path
        d="M92.7736 22.5821C93.9782 22.5821 94.7813 21.8913 95.112 20.5327L97.0253 13.0722C97.0725 12.8419 97.1907 12.7038 97.4032 12.7038C97.6394 12.7038 97.7575 12.8419 97.8284 13.0722L99.7181 20.5327C100.049 21.8913 100.852 22.5821 102.057 22.5821C103.214 22.5821 104.182 21.8913 104.513 20.5097L106.71 10.9998H104.537L102.482 20.0722C102.411 20.3485 102.293 20.4867 102.08 20.4867C101.844 20.4867 101.726 20.3485 101.655 20.1182L99.7889 12.7498C99.4346 11.3682 98.6551 10.7235 97.4269 10.7235C96.1986 10.7235 95.3955 11.3913 95.0412 12.7729L93.1515 20.1182C93.1043 20.3255 92.9625 20.4867 92.7736 20.4867C92.5138 20.4867 92.4193 20.3485 92.3484 20.0722L90.2225 10.9998H88.0258L90.2934 20.5097C90.6241 21.8913 91.6162 22.5821 92.7736 22.5821Z"
        fill={color}
      />
      <path
        d="M113.087 22.559C116.559 22.559 119.228 19.888 119.228 16.6182C119.228 13.3946 116.583 10.7235 113.087 10.7235C109.615 10.7235 106.898 13.3715 106.898 16.6182C106.898 19.888 109.591 22.559 113.087 22.559ZM113.087 20.3946C110.914 20.3946 109.237 18.6906 109.237 16.6413C109.237 14.5689 110.914 12.865 113.087 12.865C115.236 12.865 116.89 14.5689 116.89 16.6413C116.89 18.6906 115.236 20.3946 113.087 20.3946Z"
        fill={color}
      />
      <path
        d="M126.399 12.911C128.714 12.911 129.895 14.7301 129.895 16.8485V22.2827H132.233V16.5722C132.233 13.3025 130.178 10.7235 126.399 10.7235C122.667 10.7235 120.659 13.3025 120.659 16.5952V22.2827H122.997V16.8255C122.997 14.7301 124.131 12.911 126.399 12.911Z"
        fill={color}
      />
      <path
        d="M139.622 27.74C143.307 27.74 146 25.184 146 21.2696V16.6182C146 13.2564 143.331 10.7235 139.835 10.7235C136.316 10.7235 133.67 13.3715 133.67 16.5952C133.67 19.911 136.268 22.4439 139.552 22.4439C141.488 22.4439 142.906 21.5689 143.662 20.3946V21.2926C143.662 23.9406 142.008 25.6446 139.575 25.6446C137.898 25.6446 136.623 24.8617 135.938 23.6413L133.977 24.6775C135.04 26.6807 137.119 27.74 139.622 27.74ZM139.811 20.3025C137.709 20.3025 135.985 18.6446 135.985 16.5722C135.985 14.4998 137.709 12.888 139.811 12.888C141.937 12.888 143.638 14.4998 143.638 16.5722C143.638 18.6446 141.937 20.3025 139.811 20.3025Z"
        fill={color}
      />
      <path
        d="M4.0098 30.0803V22.1592C4.0098 18.4683 7.00188 15.4762 10.6928 15.4762V11.9524C4.78733 11.9524 0 16.7397 0 22.6452V29.7032C0 34.0092 3.49076 37.5 7.79683 37.5C12.1029 37.5 15.5937 34.0092 15.5937 29.7032V4.68452C23.2671 4.68452 33.5523 5.64519 33.8527 13.3128C33.8579 13.4448 33.8605 13.5786 33.8605 13.7143C33.8605 17.0903 32.2264 19.3355 30.5743 20.7427C28.5756 22.445 25.8396 22.744 23.2142 22.744H21.921C20.8871 22.744 20.049 23.5822 20.049 24.6161C20.049 25.65 20.8871 26.4881 21.921 26.4881H24.2938C26.3602 26.4881 28.4564 26.3484 30.3263 25.4688C33.4239 24.0117 37.8703 20.6702 37.8703 13.494C37.8703 6.10327 33.154 2.78003 30.0522 1.39361C28.3475 0.631691 26.4605 0.5 24.5934 0.5H14.5088C12.7704 0.5 11.3611 1.90926 11.3611 3.64768V30.0803C11.3611 32.1103 9.71545 33.756 7.68545 33.756C5.65544 33.756 4.0098 32.1103 4.0098 30.0803Z"
        fill="#8548FF"
      />
    </chakra.svg>
  );
};

export default Logo;
