import { providerConfigs } from './configs';
import { ProviderConfig } from './types';
import { ethers } from 'ethers';

let configs: Record<number, ProviderConfig> = {
  ...providerConfigs,
};

export const setConfigs = (newConfigs: Record<number, ProviderConfig>) => {
  configs = { ...configs, ...newConfigs };
};

export const getConfig = (chainId: number) =>
  configs.hasOwnProperty(chainId) ? configs[chainId] : null;

export const getProvider = (chainId: number) => {
  const config = getConfig(chainId);
  if (!config) {
    return null;
  }
  return new ethers.providers.JsonRpcProvider({
    url: config.rpcUrl,
    allowGzip: true,
  });
};
