import { Box, Input, InputGroup, InputRightElement, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineSearch } from 'react-icons/hi';
import { ColorMix, TextStyle } from 'theme/types';

const Transactions = () => {
  const { t } = useTranslation(['transaction']);
  const [where, setWhere] = useState<string>('');
  console.log('where', where);

  return (
    <Box px={10} py={10}>
      <VStack spacing={10} alignItems="flex-start" w="full" marginBottom={10}>
        <Text textStyle={TextStyle.H3} fontWeight="semibold">
          {t('transaction:title')}
        </Text>
        <Box ml={10} mr={10} justifyContent="space-between">
          <InputGroup bg={ColorMix.bgItem} h={11} w={96}>
            <Input
              placeholder={t('transaction:searchPlaceholder')}
              onChange={(e) => {
                setInterval(() => {
                  setWhere(e.target.value.trim());
                }, 500);
              }}
              textColor={ColorMix.textSub}
            />
            <InputRightElement>
              <HiOutlineSearch opacity={0.8} />
            </InputRightElement>
          </InputGroup>
        </Box>
      </VStack>
      {/* <TableListTransactions where={where} /> */}
    </Box>
  );
};

export default Transactions;
