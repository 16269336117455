export enum NETWORK {
  ethereum = 'ethereum',
  polygon = 'polygon',
  arbitrum = 'arbitrum',
  binance = 'bsc',
  moonbeam = 'moonbeam',
  fantom = 'fantom',
  'avax-c-chain' = 'avax-c-chain',
  'binance-testnet' = 'bsc-testnet',
  'polygon-mumbai' = 'polygon-mumbai',
}

export type ProviderConfig = {
  name: string;
  isTestnet?: boolean;
  currency: string;
  rpcUrl: string;
  chainId?: number;
  scanUrl?: string;
  contracts?: {
    PayNow?: {
      address: string;
      startBlock: number;
    };
  };
};
