import { Magic } from 'magic-sdk';
import { ethers } from 'ethers';
import { getConfig } from './chains';
import { ProviderConfig } from './types';

const magicKey = process.env.REACT_APP_MAGIC_API_KEY || '';
const magics: Record<number, Magic> = {};
const magicProviders: Record<number, ethers.providers.Web3Provider> = {};

export const getMagic = (chainId: number) => {
  if (!magics[chainId]) {
    const config = getConfig(chainId);
    magics[chainId] = createMagic(config!);
  }
  return magics[chainId];
};

export const getMagicProvider = (chainId: number) => {
  if (!magicProviders[chainId]) {
    const magic = getMagic(chainId);
    // @ts-ignore
    magicProviders[chainId] = new ethers.providers.Web3Provider(magic.rpcProvider);
  }
  return magicProviders[chainId];
};

const createMagic = (config: ProviderConfig) => {
  return new Magic(magicKey, {
    network: {
      rpcUrl: config.rpcUrl,
      chainId: config.chainId,
    },
  });
};
