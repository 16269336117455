import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  json: any;
  jsonb: any;
  numeric: number;
  timestamp: any;
  timestamptz: string;
  uuid: string;
};

/** Boolean expression to filter rows from the table "Account". All fields are combined with a logical 'AND'. */
export type AccountBoolExp = {
  _and?: InputMaybe<Array<AccountBoolExp>>;
  _not?: InputMaybe<AccountBoolExp>;
  _or?: InputMaybe<Array<AccountBoolExp>>;
  apps?: InputMaybe<PaymentAppBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  members?: InputMaybe<AccountMemberBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  owner?: InputMaybe<UserBoolExp>;
  ownerId?: InputMaybe<StringComparisonExp>;
  photoUrl?: InputMaybe<StringComparisonExp>;
  primaryEmail?: InputMaybe<StringComparisonExp>;
  publicAddress?: InputMaybe<StringComparisonExp>;
  secondEmail?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  useExternalWallet?: InputMaybe<BooleanComparisonExp>;
  wallets?: InputMaybe<AccountWalletBoolExp>;
};

/** order by aggregate values of table "AccountMember" */
export type AccountMemberAggregateOrderBy = {
  avg?: InputMaybe<AccountMember_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccountMember_Max_Order_By>;
  min?: InputMaybe<AccountMember_Min_Order_By>;
  stddev?: InputMaybe<AccountMember_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AccountMember_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AccountMember_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AccountMember_Sum_Order_By>;
  var_pop?: InputMaybe<AccountMember_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AccountMember_Var_Samp_Order_By>;
  variance?: InputMaybe<AccountMember_Variance_Order_By>;
};

/** Boolean expression to filter rows from the table "AccountMember". All fields are combined with a logical 'AND'. */
export type AccountMemberBoolExp = {
  _and?: InputMaybe<Array<AccountMemberBoolExp>>;
  _not?: InputMaybe<AccountMemberBoolExp>;
  _or?: InputMaybe<Array<AccountMemberBoolExp>>;
  account?: InputMaybe<AccountBoolExp>;
  accountId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  role?: InputMaybe<AccountRoleEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "AccountMember". */
export type AccountMemberOrderBy = {
  account?: InputMaybe<AccountOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: AccountMember */
export type AccountMemberPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "AccountMember" */
export enum AccountMemberSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "AccountMember" */
export type AccountMemberSetInput = {
  role?: InputMaybe<AccountRoleEnum>;
};

export type AccountMemberUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccountMemberSetInput>;
  where: AccountMemberBoolExp;
};

/** order by avg() on columns of table "AccountMember" */
export type AccountMember_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "AccountMember" */
export type AccountMember_Max_Order_By = {
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "AccountMember" */
export type AccountMember_Min_Order_By = {
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "AccountMember" */
export type AccountMember_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "AccountMember" */
export type AccountMember_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "AccountMember" */
export type AccountMember_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "AccountMember" */
export type AccountMember_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccountMember_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountMember_StreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<AccountRoleEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "AccountMember" */
export type AccountMember_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "AccountMember" */
export type AccountMember_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "AccountMember" */
export type AccountMember_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "AccountMember" */
export type AccountMember_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "Account". */
export type AccountOrderBy = {
  appsAggregate?: InputMaybe<PaymentAppAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membersAggregate?: InputMaybe<AccountMemberAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  owner?: InputMaybe<UserOrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  photoUrl?: InputMaybe<OrderBy>;
  primaryEmail?: InputMaybe<OrderBy>;
  publicAddress?: InputMaybe<OrderBy>;
  secondEmail?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  useExternalWallet?: InputMaybe<OrderBy>;
  walletsAggregate?: InputMaybe<AccountWalletAggregateOrderBy>;
};

/** primary key columns input for table: Account */
export type AccountPkColumnsInput = {
  id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "AccountRole". All fields are combined with a logical 'AND'. */
export type AccountRoleBoolExp = {
  _and?: InputMaybe<Array<AccountRoleBoolExp>>;
  _not?: InputMaybe<AccountRoleBoolExp>;
  _or?: InputMaybe<Array<AccountRoleBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

export enum AccountRoleEnum {
  Admin = 'ADMIN',
  Viewer = 'VIEWER',
}

/** Boolean expression to compare columns of type "AccountRoleEnum". All fields are combined with logical 'AND'. */
export type AccountRoleEnumComparisonExp = {
  _eq?: InputMaybe<AccountRoleEnum>;
  _in?: InputMaybe<Array<AccountRoleEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AccountRoleEnum>;
  _nin?: InputMaybe<Array<AccountRoleEnum>>;
};

/** Ordering options when selecting data from "AccountRole". */
export type AccountRoleOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "AccountRole" */
export enum AccountRoleSelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "AccountRole" */
export type AccountRole_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccountRole_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountRole_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** select columns of table "Account" */
export enum AccountSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  PrimaryEmail = 'primaryEmail',
  /** column name */
  PublicAddress = 'publicAddress',
  /** column name */
  SecondEmail = 'secondEmail',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UseExternalWallet = 'useExternalWallet',
}

/** input type for updating data in table "Account" */
export type AccountSetInput = {
  name?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  secondEmail?: InputMaybe<Scalars['String']>;
  useExternalWallet?: InputMaybe<Scalars['Boolean']>;
};

export type AccountUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccountSetInput>;
  where: AccountBoolExp;
};

/** order by aggregate values of table "AccountWallet" */
export type AccountWalletAggregateOrderBy = {
  avg?: InputMaybe<AccountWallet_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccountWallet_Max_Order_By>;
  min?: InputMaybe<AccountWallet_Min_Order_By>;
  stddev?: InputMaybe<AccountWallet_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AccountWallet_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AccountWallet_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AccountWallet_Sum_Order_By>;
  var_pop?: InputMaybe<AccountWallet_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AccountWallet_Var_Samp_Order_By>;
  variance?: InputMaybe<AccountWallet_Variance_Order_By>;
};

/** Boolean expression to filter rows from the table "AccountWallet". All fields are combined with a logical 'AND'. */
export type AccountWalletBoolExp = {
  _and?: InputMaybe<Array<AccountWalletBoolExp>>;
  _not?: InputMaybe<AccountWalletBoolExp>;
  _or?: InputMaybe<Array<AccountWalletBoolExp>>;
  account?: InputMaybe<AccountBoolExp>;
  accountId?: InputMaybe<StringComparisonExp>;
  address?: InputMaybe<StringComparisonExp>;
  chain?: InputMaybe<ChainBoolExp>;
  chainId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "AccountWallet" */
export enum AccountWalletConstraint {
  /** unique or primary key constraint on columns "accountId", "chainId" */
  WalletAccountIdChainIdKey = 'Wallet_accountId_chainId_key',
  /** unique or primary key constraint on columns "id" */
  WalletPkey = 'Wallet_pkey',
}

/** input type for inserting data into table "AccountWallet" */
export type AccountWalletInsertInput = {
  accountId?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
};

/** on_conflict condition type for table "AccountWallet" */
export type AccountWalletOnConflict = {
  constraint: AccountWalletConstraint;
  update_columns?: Array<AccountWalletUpdateColumn>;
  where?: InputMaybe<AccountWalletBoolExp>;
};

/** Ordering options when selecting data from "AccountWallet". */
export type AccountWalletOrderBy = {
  account?: InputMaybe<AccountOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  address?: InputMaybe<OrderBy>;
  chain?: InputMaybe<ChainOrderBy>;
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: AccountWallet */
export type AccountWalletPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "AccountWallet" */
export enum AccountWalletSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Address = 'address',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "AccountWallet" */
export type AccountWalletSetInput = {
  address?: InputMaybe<Scalars['String']>;
};

/** update columns of table "AccountWallet" */
export enum AccountWalletUpdateColumn {
  /** column name */
  Address = 'address',
}

export type AccountWalletUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccountWalletSetInput>;
  where: AccountWalletBoolExp;
};

/** order by avg() on columns of table "AccountWallet" */
export type AccountWallet_Avg_Order_By = {
  chainId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "AccountWallet" */
export type AccountWallet_Max_Order_By = {
  accountId?: InputMaybe<OrderBy>;
  address?: InputMaybe<OrderBy>;
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "AccountWallet" */
export type AccountWallet_Min_Order_By = {
  accountId?: InputMaybe<OrderBy>;
  address?: InputMaybe<OrderBy>;
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "AccountWallet" */
export type AccountWallet_Stddev_Order_By = {
  chainId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "AccountWallet" */
export type AccountWallet_Stddev_Pop_Order_By = {
  chainId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "AccountWallet" */
export type AccountWallet_Stddev_Samp_Order_By = {
  chainId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "AccountWallet" */
export type AccountWallet_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccountWallet_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountWallet_StreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "AccountWallet" */
export type AccountWallet_Sum_Order_By = {
  chainId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "AccountWallet" */
export type AccountWallet_Var_Pop_Order_By = {
  chainId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "AccountWallet" */
export type AccountWallet_Var_Samp_Order_By = {
  chainId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "AccountWallet" */
export type AccountWallet_Variance_Order_By = {
  chainId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "Account" */
export type Account_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Account_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_StreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  primaryEmail?: InputMaybe<Scalars['String']>;
  publicAddress?: InputMaybe<Scalars['String']>;
  secondEmail?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  useExternalWallet?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "AddressBook". All fields are combined with a logical 'AND'. */
export type AddressBookBoolExp = {
  Account?: InputMaybe<AccountBoolExp>;
  _and?: InputMaybe<Array<AddressBookBoolExp>>;
  _not?: InputMaybe<AddressBookBoolExp>;
  _or?: InputMaybe<Array<AddressBookBoolExp>>;
  accountId?: InputMaybe<StringComparisonExp>;
  address?: InputMaybe<StringComparisonExp>;
  chain?: InputMaybe<ChainBoolExp>;
  chainId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "AddressBook" */
export enum AddressBookConstraint {
  /** unique or primary key constraint on columns "id" */
  BookWalletPkey = 'BookWallet_pkey',
}

/** input type for incrementing numeric columns in table "AddressBook" */
export type AddressBookIncInput = {
  chainId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "AddressBook" */
export type AddressBookInsertInput = {
  accountId?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "AddressBook" */
export type AddressBookOnConflict = {
  constraint: AddressBookConstraint;
  update_columns?: Array<AddressBookUpdateColumn>;
  where?: InputMaybe<AddressBookBoolExp>;
};

/** Ordering options when selecting data from "AddressBook". */
export type AddressBookOrderBy = {
  Account?: InputMaybe<AccountOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  address?: InputMaybe<OrderBy>;
  chain?: InputMaybe<ChainOrderBy>;
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: AddressBook */
export type AddressBookPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "AddressBook" */
export enum AddressBookSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Address = 'address',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "AddressBook" */
export type AddressBookSetInput = {
  address?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "AddressBook" */
export enum AddressBookUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  Name = 'name',
}

export type AddressBookUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AddressBookIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AddressBookSetInput>;
  where: AddressBookBoolExp;
};

/** Streaming cursor of the table "AddressBook" */
export type AddressBook_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AddressBook_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AddressBook_StreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

export type ApproveInvoiceInput = {
  invoiceId: Scalars['String'];
  isApproved: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to filter rows from the table "Chain". All fields are combined with a logical 'AND'. */
export type ChainBoolExp = {
  _and?: InputMaybe<Array<ChainBoolExp>>;
  _not?: InputMaybe<ChainBoolExp>;
  _or?: InputMaybe<Array<ChainBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isLive?: InputMaybe<BooleanComparisonExp>;
  isTestnet?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  tokens?: InputMaybe<TokenBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** Ordering options when selecting data from "Chain". */
export type ChainOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isLive?: InputMaybe<OrderBy>;
  isTestnet?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  tokensAggregate?: InputMaybe<TokenAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "Chain" */
export enum ChainSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsLive = 'isLive',
  /** column name */
  IsTestnet = 'isTestnet',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Streaming cursor of the table "Chain" */
export type Chain_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Chain_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Chain_StreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isLive?: InputMaybe<Scalars['Boolean']>;
  isTestnet?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "Contact". All fields are combined with a logical 'AND'. */
export type ContactBoolExp = {
  _and?: InputMaybe<Array<ContactBoolExp>>;
  _not?: InputMaybe<ContactBoolExp>;
  _or?: InputMaybe<Array<ContactBoolExp>>;
  account?: InputMaybe<AccountBoolExp>;
  accountId?: InputMaybe<StringComparisonExp>;
  addressFirst?: InputMaybe<StringComparisonExp>;
  addressSecond?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  companyName?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<CountryBoolExp>;
  countryId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  department?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  jobTitle?: InputMaybe<StringComparisonExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  postCode?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<ContactTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "Contact" */
export enum ContactConstraint {
  /** unique or primary key constraint on columns "id" */
  ContactPkey = 'Contact_pkey',
}

/** input type for incrementing numeric columns in table "Contact" */
export type ContactIncInput = {
  postCode?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Contact" */
export type ContactInsertInput = {
  accountId?: InputMaybe<Scalars['String']>;
  addressFirst?: InputMaybe<Scalars['String']>;
  addressSecond?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ContactTypeEnum>;
};

/** on_conflict condition type for table "Contact" */
export type ContactOnConflict = {
  constraint: ContactConstraint;
  update_columns?: Array<ContactUpdateColumn>;
  where?: InputMaybe<ContactBoolExp>;
};

/** Ordering options when selecting data from "Contact". */
export type ContactOrderBy = {
  account?: InputMaybe<AccountOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  addressFirst?: InputMaybe<OrderBy>;
  addressSecond?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  country?: InputMaybe<CountryOrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  department?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  jobTitle?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  postCode?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: Contact */
export type ContactPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "Contact" */
export enum ContactSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AddressFirst = 'addressFirst',
  /** column name */
  AddressSecond = 'addressSecond',
  /** column name */
  City = 'city',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Department = 'department',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitle = 'jobTitle',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PostCode = 'postCode',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "Contact" */
export type ContactSetInput = {
  accountId?: InputMaybe<Scalars['String']>;
  addressFirst?: InputMaybe<Scalars['String']>;
  addressSecond?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  department?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "ContactType". All fields are combined with a logical 'AND'. */
export type ContactTypeBoolExp = {
  _and?: InputMaybe<Array<ContactTypeBoolExp>>;
  _not?: InputMaybe<ContactTypeBoolExp>;
  _or?: InputMaybe<Array<ContactTypeBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

export enum ContactTypeEnum {
  Customer = 'CUSTOMER',
  Employe = 'EMPLOYE',
}

/** Boolean expression to compare columns of type "ContactTypeEnum". All fields are combined with logical 'AND'. */
export type ContactTypeEnumComparisonExp = {
  _eq?: InputMaybe<ContactTypeEnum>;
  _in?: InputMaybe<Array<ContactTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ContactTypeEnum>;
  _nin?: InputMaybe<Array<ContactTypeEnum>>;
};

/** Ordering options when selecting data from "ContactType". */
export type ContactTypeOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "ContactType" */
export enum ContactTypeSelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "ContactType" */
export type ContactType_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ContactType_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContactType_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Contact" */
export enum ContactUpdateColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AddressFirst = 'addressFirst',
  /** column name */
  AddressSecond = 'addressSecond',
  /** column name */
  City = 'city',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Department = 'department',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'fullName',
  /** column name */
  JobTitle = 'jobTitle',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PostCode = 'postCode',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ContactUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ContactIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContactSetInput>;
  where: ContactBoolExp;
};

/** Streaming cursor of the table "Contact" */
export type Contact_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Contact_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_StreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['String']>;
  addressFirst?: InputMaybe<Scalars['String']>;
  addressSecond?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  department?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ContactTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "Country". All fields are combined with a logical 'AND'. */
export type CountryBoolExp = {
  _and?: InputMaybe<Array<CountryBoolExp>>;
  _not?: InputMaybe<CountryBoolExp>;
  _or?: InputMaybe<Array<CountryBoolExp>>;
  dialCode?: InputMaybe<StringComparisonExp>;
  emoji?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "Country". */
export type CountryOrderBy = {
  dialCode?: InputMaybe<OrderBy>;
  emoji?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "Country" */
export enum CountrySelectColumn {
  /** column name */
  DialCode = 'dialCode',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "Country" */
export type Country_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Country_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_StreamCursorValueInput = {
  dialCode?: InputMaybe<Scalars['String']>;
  emoji?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "Currency". All fields are combined with a logical 'AND'. */
export type CurrencyBoolExp = {
  _and?: InputMaybe<Array<CurrencyBoolExp>>;
  _not?: InputMaybe<CurrencyBoolExp>;
  _or?: InputMaybe<Array<CurrencyBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isFiat?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  priceUSD?: InputMaybe<NumericComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** Ordering options when selecting data from "Currency". */
export type CurrencyOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isFiat?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priceUSD?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "Currency" */
export enum CurrencySelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsFiat = 'isFiat',
  /** column name */
  Name = 'name',
  /** column name */
  PriceUsd = 'priceUSD',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Streaming cursor of the table "Currency" */
export type Currency_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Currency_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Currency_StreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  isFiat?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  priceUSD?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** Boolean expression to filter rows from the table "Document". All fields are combined with a logical 'AND'. */
export type DocumentBoolExp = {
  _and?: InputMaybe<Array<DocumentBoolExp>>;
  _not?: InputMaybe<DocumentBoolExp>;
  _or?: InputMaybe<Array<DocumentBoolExp>>;
  account?: InputMaybe<AccountBoolExp>;
  accountId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  filePath?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  verifiedAt?: InputMaybe<TimestamptzComparisonExp>;
  verifier?: InputMaybe<UserBoolExp>;
  verifierId?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "Document". */
export type DocumentOrderBy = {
  account?: InputMaybe<AccountOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  filePath?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  verifiedAt?: InputMaybe<OrderBy>;
  verifier?: InputMaybe<UserOrderBy>;
  verifierId?: InputMaybe<OrderBy>;
};

/** select columns of table "Document" */
export enum DocumentSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VerifiedAt = 'verifiedAt',
  /** column name */
  VerifierId = 'verifierId',
}

/** Boolean expression to filter rows from the table "DocumentStatus". All fields are combined with a logical 'AND'. */
export type DocumentStatusBoolExp = {
  _and?: InputMaybe<Array<DocumentStatusBoolExp>>;
  _not?: InputMaybe<DocumentStatusBoolExp>;
  _or?: InputMaybe<Array<DocumentStatusBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "DocumentStatus". */
export type DocumentStatusOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "DocumentStatus" */
export enum DocumentStatusSelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "DocumentStatus" */
export type DocumentStatus_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DocumentStatus_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentStatus_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "DocumentType". All fields are combined with a logical 'AND'. */
export type DocumentTypeBoolExp = {
  _and?: InputMaybe<Array<DocumentTypeBoolExp>>;
  _not?: InputMaybe<DocumentTypeBoolExp>;
  _or?: InputMaybe<Array<DocumentTypeBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "DocumentType". */
export type DocumentTypeOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "DocumentType" */
export enum DocumentTypeSelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "DocumentType" */
export type DocumentType_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DocumentType_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentType_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Document" */
export type Document_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Document_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_StreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verifiedAt?: InputMaybe<Scalars['timestamptz']>;
  verifierId?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to filter rows from the table "Invoice". All fields are combined with a logical 'AND'. */
export type InvoiceBoolExp = {
  _and?: InputMaybe<Array<InvoiceBoolExp>>;
  _not?: InputMaybe<InvoiceBoolExp>;
  _or?: InputMaybe<Array<InvoiceBoolExp>>;
  account?: InputMaybe<AccountBoolExp>;
  accountId?: InputMaybe<StringComparisonExp>;
  confirmAt?: InputMaybe<TimestamptzComparisonExp>;
  contact?: InputMaybe<ContactBoolExp>;
  contractId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdById?: InputMaybe<StringComparisonExp>;
  customer?: InputMaybe<AccountBoolExp>;
  customerId?: InputMaybe<StringComparisonExp>;
  discount?: InputMaybe<NumericComparisonExp>;
  dueAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isApproved?: InputMaybe<BooleanComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  isDrafted?: InputMaybe<BooleanComparisonExp>;
  isExpired?: InputMaybe<BooleanComparisonExp>;
  isPaid?: InputMaybe<BooleanComparisonExp>;
  items?: InputMaybe<InvoiceItemBoolExp>;
  note?: InputMaybe<StringComparisonExp>;
  payment?: InputMaybe<PaymentBoolExp>;
  paymentId?: InputMaybe<StringComparisonExp>;
  receiverAddress?: InputMaybe<StringComparisonExp>;
  tax?: InputMaybe<NumericComparisonExp>;
  token?: InputMaybe<TokenBoolExp>;
  tokenId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  vatId?: InputMaybe<StringComparisonExp>;
};

/** input type for incrementing numeric columns in table "Invoice" */
export type InvoiceIncInput = {
  discount?: InputMaybe<Scalars['numeric']>;
  tax?: InputMaybe<Scalars['numeric']>;
};

/** order by aggregate values of table "InvoiceItem" */
export type InvoiceItemAggregateOrderBy = {
  avg?: InputMaybe<InvoiceItem_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InvoiceItem_Max_Order_By>;
  min?: InputMaybe<InvoiceItem_Min_Order_By>;
  stddev?: InputMaybe<InvoiceItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<InvoiceItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<InvoiceItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<InvoiceItem_Sum_Order_By>;
  var_pop?: InputMaybe<InvoiceItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<InvoiceItem_Var_Samp_Order_By>;
  variance?: InputMaybe<InvoiceItem_Variance_Order_By>;
};

/** Boolean expression to filter rows from the table "InvoiceItem". All fields are combined with a logical 'AND'. */
export type InvoiceItemBoolExp = {
  _and?: InputMaybe<Array<InvoiceItemBoolExp>>;
  _not?: InputMaybe<InvoiceItemBoolExp>;
  _or?: InputMaybe<Array<InvoiceItemBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  quality?: InputMaybe<IntComparisonExp>;
  total?: InputMaybe<NumericComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "InvoiceItem" */
export enum InvoiceItemConstraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceItemPkey = 'InvoiceItem_pkey',
}

/** input type for incrementing numeric columns in table "InvoiceItem" */
export type InvoiceItemIncInput = {
  price?: InputMaybe<Scalars['numeric']>;
  quality?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "InvoiceItem" */
export type InvoiceItemInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  quality?: InputMaybe<Scalars['Int']>;
};

/** on_conflict condition type for table "InvoiceItem" */
export type InvoiceItemOnConflict = {
  constraint: InvoiceItemConstraint;
  update_columns?: Array<InvoiceItemUpdateColumn>;
  where?: InputMaybe<InvoiceItemBoolExp>;
};

/** Ordering options when selecting data from "InvoiceItem". */
export type InvoiceItemOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: InvoiceItem */
export type InvoiceItemPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "InvoiceItem" */
export enum InvoiceItemSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Quality = 'quality',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "InvoiceItem" */
export type InvoiceItemSetInput = {
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  quality?: InputMaybe<Scalars['Int']>;
};

/** update columns of table "InvoiceItem" */
export enum InvoiceItemUpdateColumn {
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Quality = 'quality',
}

export type InvoiceItemUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InvoiceItemIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceItemSetInput>;
  where: InvoiceItemBoolExp;
};

/** order by avg() on columns of table "InvoiceItem" */
export type InvoiceItem_Avg_Order_By = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "InvoiceItem" */
export type InvoiceItem_Max_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "InvoiceItem" */
export type InvoiceItem_Min_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "InvoiceItem" */
export type InvoiceItem_Stddev_Order_By = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "InvoiceItem" */
export type InvoiceItem_Stddev_Pop_Order_By = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "InvoiceItem" */
export type InvoiceItem_Stddev_Samp_Order_By = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "InvoiceItem" */
export type InvoiceItem_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: InvoiceItem_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceItem_StreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  quality?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "InvoiceItem" */
export type InvoiceItem_Sum_Order_By = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "InvoiceItem" */
export type InvoiceItem_Var_Pop_Order_By = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "InvoiceItem" */
export type InvoiceItem_Var_Samp_Order_By = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "InvoiceItem" */
export type InvoiceItem_Variance_Order_By = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "Invoice". */
export type InvoiceOrderBy = {
  account?: InputMaybe<AccountOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  confirmAt?: InputMaybe<OrderBy>;
  contact?: InputMaybe<ContactOrderBy>;
  contractId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  customer?: InputMaybe<AccountOrderBy>;
  customerId?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  dueAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isApproved?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  isDrafted?: InputMaybe<OrderBy>;
  isExpired?: InputMaybe<OrderBy>;
  isPaid?: InputMaybe<OrderBy>;
  itemsAggregate?: InputMaybe<InvoiceItemAggregateOrderBy>;
  note?: InputMaybe<OrderBy>;
  payment?: InputMaybe<PaymentOrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  receiverAddress?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  token?: InputMaybe<TokenOrderBy>;
  tokenId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  vatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: Invoice */
export type InvoicePkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "Invoice" */
export enum InvoiceSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ConfirmAt = 'confirmAt',
  /** column name */
  ContractId = 'contractId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Discount = 'discount',
  /** column name */
  DueAt = 'dueAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsDrafted = 'isDrafted',
  /** column name */
  IsPaid = 'isPaid',
  /** column name */
  Note = 'note',
  /** column name */
  PaymentId = 'paymentId',
  /** column name */
  ReceiverAddress = 'receiverAddress',
  /** column name */
  Tax = 'tax',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VatId = 'vatId',
}

/** input type for updating data in table "Invoice" */
export type InvoiceSetInput = {
  contractId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  discount?: InputMaybe<Scalars['numeric']>;
  dueAt?: InputMaybe<Scalars['timestamptz']>;
  note?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Scalars['numeric']>;
  tokenId?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
};

export type InvoiceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InvoiceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceSetInput>;
  where: InvoiceBoolExp;
};

/** Streaming cursor of the table "Invoice" */
export type Invoice_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Invoice_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_StreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['String']>;
  confirmAt?: InputMaybe<Scalars['timestamptz']>;
  contractId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['numeric']>;
  dueAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isDrafted?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['String']>;
  receiverAddress?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Scalars['numeric']>;
  tokenId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vatId?: InputMaybe<Scalars['String']>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST',
}

/** order by aggregate values of table "PaymentApp" */
export type PaymentAppAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PaymentApp_Max_Order_By>;
  min?: InputMaybe<PaymentApp_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PaymentAppAppendInput = {
  extras?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "PaymentApp". All fields are combined with a logical 'AND'. */
export type PaymentAppBoolExp = {
  Kind?: InputMaybe<PaymentKindBoolExp>;
  _and?: InputMaybe<Array<PaymentAppBoolExp>>;
  _not?: InputMaybe<PaymentAppBoolExp>;
  _or?: InputMaybe<Array<PaymentAppBoolExp>>;
  account?: InputMaybe<AccountBoolExp>;
  accountId?: InputMaybe<StringComparisonExp>;
  apiToken?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  extras?: InputMaybe<JsonbComparisonExp>;
  homepage?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isEnabled?: InputMaybe<BooleanComparisonExp>;
  kind?: InputMaybe<PaymentKindEnumComparisonExp>;
  logoUrl?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  salt?: InputMaybe<StringComparisonExp>;
  tokens?: InputMaybe<PaymentAppTokenBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  webhookUrl?: InputMaybe<StringComparisonExp>;
  whitelistedDomain?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "PaymentApp" */
export enum PaymentAppConstraint {
  /** unique or primary key constraint on columns "id" */
  PaymentAppPkey = 'PaymentApp_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PaymentAppDeleteAtPathInput = {
  extras?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PaymentAppDeleteElemInput = {
  extras?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PaymentAppDeleteKeyInput = {
  extras?: InputMaybe<Scalars['String']>;
};

export type PaymentAppInput = {
  accountId: Scalars['String'];
};

/** input type for inserting data into table "PaymentApp" */
export type PaymentAppInsertInput = {
  accountId?: InputMaybe<Scalars['String']>;
  apiToken?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  tokens?: InputMaybe<PaymentAppTokenArrRelInsertInput>;
};

/** input type for inserting object relation for remote table "PaymentApp" */
export type PaymentAppObjRelInsertInput = {
  data: PaymentAppInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentAppOnConflict>;
};

/** on_conflict condition type for table "PaymentApp" */
export type PaymentAppOnConflict = {
  constraint: PaymentAppConstraint;
  update_columns?: Array<PaymentAppUpdateColumn>;
  where?: InputMaybe<PaymentAppBoolExp>;
};

/** Ordering options when selecting data from "PaymentApp". */
export type PaymentAppOrderBy = {
  Kind?: InputMaybe<PaymentKindOrderBy>;
  account?: InputMaybe<AccountOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  apiToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  extras?: InputMaybe<OrderBy>;
  homepage?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isEnabled?: InputMaybe<OrderBy>;
  kind?: InputMaybe<OrderBy>;
  logoUrl?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  salt?: InputMaybe<OrderBy>;
  tokensAggregate?: InputMaybe<PaymentAppTokenAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  webhookUrl?: InputMaybe<OrderBy>;
  whitelistedDomain?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: PaymentApp */
export type PaymentAppPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PaymentAppPrependInput = {
  extras?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "PaymentApp" */
export enum PaymentAppSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ApiToken = 'apiToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Extras = 'extras',
  /** column name */
  Homepage = 'homepage',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'isEnabled',
  /** column name */
  Kind = 'kind',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  Name = 'name',
  /** column name */
  Salt = 'salt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebhookUrl = 'webhookUrl',
  /** column name */
  WhitelistedDomain = 'whitelistedDomain',
}

/** input type for updating data in table "PaymentApp" */
export type PaymentAppSetInput = {
  extras?: InputMaybe<Scalars['jsonb']>;
  homepage?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  webhookUrl?: InputMaybe<Scalars['String']>;
  whitelistedDomain?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "PaymentAppToken" */
export type PaymentAppTokenAggregateOrderBy = {
  avg?: InputMaybe<PaymentAppToken_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PaymentAppToken_Max_Order_By>;
  min?: InputMaybe<PaymentAppToken_Min_Order_By>;
  stddev?: InputMaybe<PaymentAppToken_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PaymentAppToken_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PaymentAppToken_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PaymentAppToken_Sum_Order_By>;
  var_pop?: InputMaybe<PaymentAppToken_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PaymentAppToken_Var_Samp_Order_By>;
  variance?: InputMaybe<PaymentAppToken_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PaymentAppToken" */
export type PaymentAppTokenArrRelInsertInput = {
  data: Array<PaymentAppTokenInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentAppTokenOnConflict>;
};

/** Boolean expression to filter rows from the table "PaymentAppToken". All fields are combined with a logical 'AND'. */
export type PaymentAppTokenBoolExp = {
  _and?: InputMaybe<Array<PaymentAppTokenBoolExp>>;
  _not?: InputMaybe<PaymentAppTokenBoolExp>;
  _or?: InputMaybe<Array<PaymentAppTokenBoolExp>>;
  app?: InputMaybe<PaymentAppBoolExp>;
  appId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  token?: InputMaybe<TokenBoolExp>;
  tokenId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "PaymentAppToken" */
export enum PaymentAppTokenConstraint {
  /** unique or primary key constraint on columns "tokenId", "appId" */
  PaymentAppTokenAppIdTokenIdKey = 'PaymentAppToken_appId_tokenId_key',
  /** unique or primary key constraint on columns "id" */
  PaymentAppTokenPkey = 'PaymentAppToken_pkey',
}

/** input type for inserting data into table "PaymentAppToken" */
export type PaymentAppTokenInsertInput = {
  app?: InputMaybe<PaymentAppObjRelInsertInput>;
  appId?: InputMaybe<Scalars['uuid']>;
  tokenId?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "PaymentAppToken" */
export type PaymentAppTokenOnConflict = {
  constraint: PaymentAppTokenConstraint;
  update_columns?: Array<PaymentAppTokenUpdateColumn>;
  where?: InputMaybe<PaymentAppTokenBoolExp>;
};

/** Ordering options when selecting data from "PaymentAppToken". */
export type PaymentAppTokenOrderBy = {
  app?: InputMaybe<PaymentAppOrderBy>;
  appId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  token?: InputMaybe<TokenOrderBy>;
  tokenId?: InputMaybe<OrderBy>;
};

/** select columns of table "PaymentAppToken" */
export enum PaymentAppTokenSelectColumn {
  /** column name */
  AppId = 'appId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TokenId = 'tokenId',
}

/** placeholder for update columns of table "PaymentAppToken" (current role has no relevant permissions) */
export enum PaymentAppTokenUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by avg() on columns of table "PaymentAppToken" */
export type PaymentAppToken_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "PaymentAppToken" */
export type PaymentAppToken_Max_Order_By = {
  appId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  tokenId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "PaymentAppToken" */
export type PaymentAppToken_Min_Order_By = {
  appId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  tokenId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "PaymentAppToken" */
export type PaymentAppToken_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "PaymentAppToken" */
export type PaymentAppToken_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "PaymentAppToken" */
export type PaymentAppToken_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "PaymentAppToken" */
export type PaymentAppToken_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentAppToken_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentAppToken_StreamCursorValueInput = {
  appId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tokenId?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "PaymentAppToken" */
export type PaymentAppToken_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "PaymentAppToken" */
export type PaymentAppToken_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "PaymentAppToken" */
export type PaymentAppToken_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "PaymentAppToken" */
export type PaymentAppToken_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "PaymentApp" */
export enum PaymentAppUpdateColumn {
  /** column name */
  Extras = 'extras',
  /** column name */
  Homepage = 'homepage',
  /** column name */
  IsEnabled = 'isEnabled',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  Name = 'name',
  /** column name */
  WebhookUrl = 'webhookUrl',
  /** column name */
  WhitelistedDomain = 'whitelistedDomain',
}

export type PaymentAppUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PaymentAppAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<PaymentAppDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<PaymentAppDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<PaymentAppDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PaymentAppPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentAppSetInput>;
  where: PaymentAppBoolExp;
};

/** order by max() on columns of table "PaymentApp" */
export type PaymentApp_Max_Order_By = {
  accountId?: InputMaybe<OrderBy>;
  apiToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  homepage?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  logoUrl?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  salt?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  webhookUrl?: InputMaybe<OrderBy>;
  whitelistedDomain?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "PaymentApp" */
export type PaymentApp_Min_Order_By = {
  accountId?: InputMaybe<OrderBy>;
  apiToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  homepage?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  logoUrl?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  salt?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  webhookUrl?: InputMaybe<OrderBy>;
  whitelistedDomain?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "PaymentApp" */
export type PaymentApp_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentApp_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentApp_StreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['String']>;
  apiToken?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  extras?: InputMaybe<Scalars['jsonb']>;
  homepage?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<PaymentKindEnum>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  webhookUrl?: InputMaybe<Scalars['String']>;
  whitelistedDomain?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "Payment". All fields are combined with a logical 'AND'. */
export type PaymentBoolExp = {
  _and?: InputMaybe<Array<PaymentBoolExp>>;
  _not?: InputMaybe<PaymentBoolExp>;
  _or?: InputMaybe<Array<PaymentBoolExp>>;
  account?: InputMaybe<AccountBoolExp>;
  accountId?: InputMaybe<StringComparisonExp>;
  app?: InputMaybe<PaymentAppBoolExp>;
  appId?: InputMaybe<UuidComparisonExp>;
  cryptoPrice?: InputMaybe<NumericComparisonExp>;
  cryptoTokenId?: InputMaybe<StringComparisonExp>;
  customerEmail?: InputMaybe<StringComparisonExp>;
  customerName?: InputMaybe<StringComparisonExp>;
  expiredAt?: InputMaybe<TimestamptzComparisonExp>;
  fiatCurrency?: InputMaybe<CurrencyBoolExp>;
  fiatCurrencyId?: InputMaybe<StringComparisonExp>;
  fiatPrice?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  isFiatSource?: InputMaybe<BooleanComparisonExp>;
  isLocked?: InputMaybe<BooleanComparisonExp>;
  isValidated?: InputMaybe<BooleanComparisonExp>;
  lockedAt?: InputMaybe<TimestamptzComparisonExp>;
  orderDescription?: InputMaybe<StringComparisonExp>;
  orderId?: InputMaybe<StringComparisonExp>;
  receiverAddress?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  token?: InputMaybe<TokenBoolExp>;
  transactionId?: InputMaybe<UuidComparisonExp>;
  txHash?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to filter rows from the table "PaymentKind". All fields are combined with a logical 'AND'. */
export type PaymentKindBoolExp = {
  _and?: InputMaybe<Array<PaymentKindBoolExp>>;
  _not?: InputMaybe<PaymentKindBoolExp>;
  _or?: InputMaybe<Array<PaymentKindBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

export enum PaymentKindEnum {
  Connector = 'CONNECTOR',
  Invoice = 'INVOICE',
}

/** Boolean expression to compare columns of type "PaymentKindEnum". All fields are combined with logical 'AND'. */
export type PaymentKindEnumComparisonExp = {
  _eq?: InputMaybe<PaymentKindEnum>;
  _in?: InputMaybe<Array<PaymentKindEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PaymentKindEnum>;
  _nin?: InputMaybe<Array<PaymentKindEnum>>;
};

/** Ordering options when selecting data from "PaymentKind". */
export type PaymentKindOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "PaymentKind" */
export enum PaymentKindSelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "PaymentKind" */
export type PaymentKind_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentKind_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentKind_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Payment". */
export type PaymentOrderBy = {
  account?: InputMaybe<AccountOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  app?: InputMaybe<PaymentAppOrderBy>;
  appId?: InputMaybe<OrderBy>;
  cryptoPrice?: InputMaybe<OrderBy>;
  cryptoTokenId?: InputMaybe<OrderBy>;
  customerEmail?: InputMaybe<OrderBy>;
  customerName?: InputMaybe<OrderBy>;
  expiredAt?: InputMaybe<OrderBy>;
  fiatCurrency?: InputMaybe<CurrencyOrderBy>;
  fiatCurrencyId?: InputMaybe<OrderBy>;
  fiatPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  isFiatSource?: InputMaybe<OrderBy>;
  isLocked?: InputMaybe<OrderBy>;
  isValidated?: InputMaybe<OrderBy>;
  lockedAt?: InputMaybe<OrderBy>;
  orderDescription?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  receiverAddress?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  token?: InputMaybe<TokenOrderBy>;
  transactionId?: InputMaybe<OrderBy>;
  txHash?: InputMaybe<OrderBy>;
};

/** select columns of table "Payment" */
export enum PaymentSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AppId = 'appId',
  /** column name */
  CryptoPrice = 'cryptoPrice',
  /** column name */
  CryptoTokenId = 'cryptoTokenId',
  /** column name */
  CustomerEmail = 'customerEmail',
  /** column name */
  CustomerName = 'customerName',
  /** column name */
  ExpiredAt = 'expiredAt',
  /** column name */
  FiatCurrencyId = 'fiatCurrencyId',
  /** column name */
  FiatPrice = 'fiatPrice',
  /** column name */
  Id = 'id',
  /** column name */
  IsFiatSource = 'isFiatSource',
  /** column name */
  IsValidated = 'isValidated',
  /** column name */
  LockedAt = 'lockedAt',
  /** column name */
  OrderDescription = 'orderDescription',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  ReceiverAddress = 'receiverAddress',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  TxHash = 'txHash',
}

/** Streaming cursor of the table "Payment" */
export type Payment_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Payment_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_StreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['String']>;
  appId?: InputMaybe<Scalars['uuid']>;
  cryptoPrice?: InputMaybe<Scalars['numeric']>;
  cryptoTokenId?: InputMaybe<Scalars['String']>;
  customerEmail?: InputMaybe<Scalars['String']>;
  customerName?: InputMaybe<Scalars['String']>;
  expiredAt?: InputMaybe<Scalars['timestamptz']>;
  fiatCurrencyId?: InputMaybe<Scalars['String']>;
  fiatPrice?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['String']>;
  isFiatSource?: InputMaybe<Scalars['Boolean']>;
  isValidated?: InputMaybe<Scalars['Boolean']>;
  lockedAt?: InputMaybe<Scalars['timestamptz']>;
  orderDescription?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  receiverAddress?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['uuid']>;
  txHash?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** order by aggregate values of table "Token" */
export type TokenAggregateOrderBy = {
  avg?: InputMaybe<Token_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Token_Max_Order_By>;
  min?: InputMaybe<Token_Min_Order_By>;
  stddev?: InputMaybe<Token_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Token_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Token_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Token_Sum_Order_By>;
  var_pop?: InputMaybe<Token_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Token_Var_Samp_Order_By>;
  variance?: InputMaybe<Token_Variance_Order_By>;
};

/** Boolean expression to filter rows from the table "Token". All fields are combined with a logical 'AND'. */
export type TokenBoolExp = {
  _and?: InputMaybe<Array<TokenBoolExp>>;
  _not?: InputMaybe<TokenBoolExp>;
  _or?: InputMaybe<Array<TokenBoolExp>>;
  chain?: InputMaybe<ChainBoolExp>;
  chainId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<StringComparisonExp>;
  decimals?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isEnable?: InputMaybe<BooleanComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** Ordering options when selecting data from "Token". */
export type TokenOrderBy = {
  chain?: InputMaybe<ChainOrderBy>;
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isEnable?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "Token" */
export enum TokenSelectColumn {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnable = 'isEnable',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** order by avg() on columns of table "Token" */
export type Token_Avg_Order_By = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "Token" */
export type Token_Max_Order_By = {
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "Token" */
export type Token_Min_Order_By = {
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "Token" */
export type Token_Stddev_Order_By = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "Token" */
export type Token_Stddev_Pop_Order_By = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "Token" */
export type Token_Stddev_Samp_Order_By = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "Token" */
export type Token_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Token_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Token_StreamCursorValueInput = {
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['String']>;
  decimals?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isEnable?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "Token" */
export type Token_Sum_Order_By = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "Token" */
export type Token_Var_Pop_Order_By = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "Token" */
export type Token_Var_Samp_Order_By = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "Token" */
export type Token_Variance_Order_By = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

export enum UploadType {
  Avatar = 'AVATAR',
  Brand = 'BRAND',
  GovernmentId = 'GOVERNMENT_ID',
  RegisteredAddress = 'REGISTERED_ADDRESS',
  TaxRegistration = 'TAX_REGISTRATION',
  VideoVerification = 'VIDEO_VERIFICATION',
}

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  _and?: InputMaybe<Array<UserBoolExp>>;
  _not?: InputMaybe<UserBoolExp>;
  _or?: InputMaybe<Array<UserBoolExp>>;
  account?: InputMaybe<AccountBoolExp>;
  address?: InputMaybe<StringComparisonExp>;
  avatarUrl?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  companyGstRegistration?: InputMaybe<StringComparisonExp>;
  companyName?: InputMaybe<StringComparisonExp>;
  companyRole?: InputMaybe<StringComparisonExp>;
  countryId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isBusiness?: InputMaybe<BooleanComparisonExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** Ordering options when selecting data from "User". */
export type UserOrderBy = {
  account?: InputMaybe<AccountOrderBy>;
  address?: InputMaybe<OrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  companyGstRegistration?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  companyRole?: InputMaybe<OrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isBusiness?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: User */
export type UserPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "User" */
export enum UserSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  City = 'city',
  /** column name */
  CompanyGstRegistration = 'companyGstRegistration',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CompanyRole = 'companyRole',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  IsBusiness = 'isBusiness',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "User" */
export type UserSetInput = {
  address?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyGstRegistration?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyRole?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  isBusiness?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};

/** Streaming cursor of the table "User" */
export type User_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: User_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type User_StreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyGstRegistration?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyRole?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isBusiness?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type AccountFragment = {
  __typename?: 'Account';
  id: string;
  publicAddress: string;
  useExternalWallet: boolean;
  ownerId: string;
  photoUrl?: string | null | undefined;
  primaryEmail: string;
  secondEmail?: string | null | undefined;
  updatedAt: string;
  name?: string | null | undefined;
  createdAt: string;
};

export type AccountWalletFragment = {
  __typename?: 'AccountWallet';
  id: string;
  accountId: string;
  chainId: number;
  address?: string | null | undefined;
  createdAt?: string | null | undefined;
  updatedAt?: string | null | undefined;
};

export type AddressBookFragment = {
  __typename?: 'AddressBook';
  id: any;
  name: string;
  chainId: number;
  address: string;
  createdAt: string;
  updatedAt: string;
  accountId: string;
  chain: { __typename?: 'Chain' } & ChainFragment;
};

export type ChainFragment = {
  __typename?: 'Chain';
  id: number;
  name: string;
  currencyId: string;
  isTestnet: boolean;
  updatedAt: string;
  isLive: boolean;
  createdAt: string;
};

export type ContactFragment = {
  __typename?: 'Contact';
  id: string;
  fullName: string;
  companyName?: string | null | undefined;
  email: string;
  addressFirst?: string | null | undefined;
  addressSecond?: string | null | undefined;
  city?: string | null | undefined;
  type: ContactTypeEnum;
  postCode?: number | null | undefined;
  countryId?: string | null | undefined;
  department?: string | null | undefined;
  jobTitle?: string | null | undefined;
  description?: string | null | undefined;
  createdAt: string;
  phoneNumber?: string | null | undefined;
  updatedAt: string;
  accountId: string;
};

export type CountryFragment = {
  __typename?: 'Country';
  id: string;
  name: string;
  dialCode: string;
  emoji: string;
};

export type CurrencyFragment = {
  __typename?: 'Currency';
  id: string;
  name: string;
  priceUSD?: number | null | undefined;
  updatedAt: string;
  isFiat: boolean;
};

export type InvoiceFragment = {
  __typename?: 'Invoice';
  id: string;
  contractId?: string | null | undefined;
  createdAt: any;
  customerId?: string | null | undefined;
  discount?: number | null | undefined;
  confirmAt?: string | null | undefined;
  dueAt?: string | null | undefined;
  isApproved?: boolean | null | undefined;
  isDeleted: boolean;
  isDrafted: boolean;
  isExpired?: boolean | null | undefined;
  isPaid: boolean;
  note?: string | null | undefined;
  paymentId?: string | null | undefined;
  tax?: number | null | undefined;
  tokenId?: string | null | undefined;
  updatedAt: string;
  vatId?: string | null | undefined;
  account: {
    __typename?: 'Account';
    members: Array<{ __typename?: 'AccountMember' } & MemberFragment>;
  } & AccountFragment;
  token?:
    | ({ __typename?: 'Token'; chain: { __typename?: 'Chain' } & ChainFragment } & TokenFragment)
    | null
    | undefined;
  items: Array<{ __typename?: 'InvoiceItem' } & InvoiceItemFragment>;
  contact?:
    | { __typename?: 'Contact'; id: string; fullName: string; email: string }
    | null
    | undefined;
  customer?:
    | {
        __typename?: 'Account';
        id: string;
        name?: string | null | undefined;
        primaryEmail: string;
        photoUrl?: string | null | undefined;
        ownerId: string;
      }
    | null
    | undefined;
};

export type InvoiceItemFragment = {
  __typename?: 'InvoiceItem';
  id: string;
  name: string;
  quality: number;
  price: number;
  total: number;
};

export type MemberFragment = {
  __typename?: 'AccountMember';
  id: number;
  accountId: string;
  role: AccountRoleEnum;
  updatedAt?: string | null | undefined;
  userId: string;
  createdAt?: string | null | undefined;
};

export type PaymentFragment = {
  __typename?: 'Payment';
  id: string;
  appId: string;
  cryptoPrice?: number | null | undefined;
  cryptoTokenId?: string | null | undefined;
  expiredAt: string;
  fiatCurrencyId?: string | null | undefined;
  fiatPrice?: number | null | undefined;
  isFiatSource: boolean;
  isLocked?: boolean | null | undefined;
  isValidated?: boolean | null | undefined;
  lockedAt?: string | null | undefined;
  orderDescription?: string | null | undefined;
  orderId?: string | null | undefined;
  receiverAddress: string;
  status?: string | null | undefined;
  transactionId?: string | null | undefined;
  txHash?: string | null | undefined;
  accountId: string;
  token?:
    | ({
        __typename?: 'Token';
        id: string;
        decimals: number;
        currency: { __typename?: 'Currency'; id: string; name: string };
        chain: {
          __typename?: 'Chain';
          id: number;
          name: string;
          currency: { __typename?: 'Currency'; id: string; name: string };
        };
      } & TokenFragment)
    | null
    | undefined;
  invoice?: ({ __typename?: 'Invoice' } & InvoiceFragment) | null | undefined;
  app: { __typename?: 'PaymentApp' } & PaymentAppFragment;
};

export type PaymentAppFragment = {
  __typename?: 'PaymentApp';
  id: string;
  extras?: any | null | undefined;
  createdAt: string;
  homepage?: string | null | undefined;
  kind: PaymentKindEnum;
  name?: string | null | undefined;
  logoUrl?: string | null | undefined;
  updatedAt: string;
  whitelistedDomain?: string | null | undefined;
  accountId: string;
  apiToken?: string | null | undefined;
  webhookUrl?: string | null | undefined;
  isEnabled?: boolean | null | undefined;
};

export type PaymentAppTokenFragment = {
  __typename?: 'PaymentAppToken';
  id: number;
  appId: string;
  tokenId: string;
  token: { __typename?: 'Token'; id: string; chainId: number };
};

export type TokenFragment = {
  __typename?: 'Token';
  id: string;
  decimals: number;
  currencyId: string;
  updatedAt: string;
  chainId: number;
  isEnable: boolean;
  chain: { __typename?: 'Chain' } & ChainFragment;
};

export type UserFragment = {
  __typename?: 'User';
  id: string;
  fullName?: string | null | undefined;
  email: string;
  createdAt?: string | null | undefined;
  companyRole?: string | null | undefined;
  companyName?: string | null | undefined;
  companyGstRegistration?: string | null | undefined;
  avatarUrl?: string | null | undefined;
  isBusiness: boolean;
  phoneNumber?: string | null | undefined;
  updatedAt?: string | null | undefined;
  city?: string | null | undefined;
  countryId?: string | null | undefined;
  address?: string | null | undefined;
};

export type AddAddressBookMutationVariables = Exact<{
  object?: InputMaybe<AddressBookInsertInput>;
}>;

export type AddAddressBookMutation = {
  __typename?: 'mutation_root';
  AddressBook?: ({ __typename?: 'AddressBook' } & AddressBookFragment) | null | undefined;
};

export type AddContactMutationVariables = Exact<{
  object?: InputMaybe<ContactInsertInput>;
}>;

export type AddContactMutation = {
  __typename?: 'mutation_root';
  Contact?: ({ __typename?: 'Contact' } & ContactFragment) | null | undefined;
};

export type ApproveInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String'];
  isApproved?: InputMaybe<Scalars['Boolean']>;
}>;

export type ApproveInvoiceMutation = {
  __typename?: 'mutation_root';
  approveInvoice?:
    | {
        __typename?: 'ApproveInvoiceOutput';
        invoice?:
          | ({
              __typename?: 'Invoice';
              payment?: ({ __typename?: 'Payment' } & PaymentFragment) | null | undefined;
            } & InvoiceFragment)
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateDraftInvoiceMutationVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type CreateDraftInvoiceMutation = {
  __typename?: 'mutation_root';
  createDraftInvoice?: { __typename?: 'DraftInvoiceOutput'; invoiceId: string } | null | undefined;
};

export type CreatePaymentAppMutationVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type CreatePaymentAppMutation = {
  __typename?: 'mutation_root';
  createPaymentApp?:
    | {
        __typename?: 'PaymentAppOutput';
        paymentAppId: string;
        app?: ({ __typename?: 'PaymentApp' } & PaymentAppFragment) | null | undefined;
      }
    | null
    | undefined;
};

export type DeleteAddressBookMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;

export type DeleteAddressBookMutation = {
  __typename?: 'mutation_root';
  deleteAddressBookByPk?: ({ __typename?: 'AddressBook' } & AddressBookFragment) | null | undefined;
};

export type DeletePaymentAppMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeletePaymentAppMutation = {
  __typename?: 'mutation_root';
  softDeletePaymentApp?: { __typename?: 'DeletePaymentAppOutput'; id: string } | null | undefined;
};

export type LoginMutationVariables = Exact<{
  didToken: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'mutation_root';
  login?: { __typename?: 'LoginOutput'; token: string } | null | undefined;
};

export type RemoveInvoiceMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type RemoveInvoiceMutation = {
  __typename?: 'mutation_root';
  deleteInvoiceByPk?: ({ __typename?: 'Invoice' } & InvoiceFragment) | null | undefined;
};

export type CreatePresignedUrlMutationVariables = Exact<{
  type?: InputMaybe<UploadType>;
}>;

export type CreatePresignedUrlMutation = {
  __typename?: 'mutation_root';
  requestUpload?:
    | { __typename?: 'RequestUploadOutput'; fields: any; postUrl: string; fileUrl: string }
    | null
    | undefined;
};

export type ResetPaymentAppTokenMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type ResetPaymentAppTokenMutation = {
  __typename?: 'mutation_root';
  resetPaymentAppToken?:
    | {
        __typename?: 'PaymentAppTokenOutput';
        appId: string;
        app?: ({ __typename?: 'PaymentApp' } & PaymentAppFragment) | null | undefined;
      }
    | null
    | undefined;
};

export type SendInvoiceMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type SendInvoiceMutation = {
  __typename?: 'mutation_root';
  sendInvoice?: { __typename?: 'SendInvoiceOutput'; invoiceId: string } | null | undefined;
};

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['String'];
  _set?: InputMaybe<AccountSetInput>;
}>;

export type UpdateAccountMutation = {
  __typename?: 'mutation_root';
  account?: ({ __typename?: 'Account' } & AccountFragment) | null | undefined;
};

export type UpdateAddressBookMutationVariables = Exact<{
  _set?: InputMaybe<AddressBookSetInput>;
  id: Scalars['bigint'];
}>;

export type UpdateAddressBookMutation = {
  __typename?: 'mutation_root';
  updateAddressBookByPk?: ({ __typename?: 'AddressBook' } & AddressBookFragment) | null | undefined;
};

export type UpdateContractMutationVariables = Exact<{
  _set?: InputMaybe<ContactSetInput>;
  id: Scalars['uuid'];
}>;

export type UpdateContractMutation = {
  __typename?: 'mutation_root';
  updateContactByPk?: ({ __typename?: 'Contact' } & ContactFragment) | null | undefined;
};

export type UpdateInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String'];
  invoiceSetInput?: InputMaybe<InvoiceSetInput>;
  itemInputs?: InputMaybe<Array<InvoiceItemInsertInput> | InvoiceItemInsertInput>;
  itemDeleteIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type UpdateInvoiceMutation = {
  __typename?: 'mutation_root';
  deleteInvoiceItem?:
    | { __typename?: 'InvoiceItemMutationResponse'; affected_rows: number }
    | null
    | undefined;
  insertInvoiceItem?:
    | { __typename?: 'InvoiceItemMutationResponse'; affected_rows: number }
    | null
    | undefined;
  invoice?: ({ __typename?: 'Invoice' } & InvoiceFragment) | null | undefined;
};

export type UpdateInvoiceItemMutationVariables = Exact<{
  id?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  _set?: InputMaybe<InvoiceItemSetInput>;
}>;

export type UpdateInvoiceItemMutation = {
  __typename?: 'mutation_root';
  updateInvoiceItem?:
    | { __typename?: 'InvoiceItemMutationResponse'; affected_rows: number }
    | null
    | undefined;
};

export type UpdateInvoiceItemByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: InputMaybe<InvoiceItemSetInput>;
}>;

export type UpdateInvoiceItemByPkMutation = {
  __typename?: 'mutation_root';
  updateInvoiceItemByPk?: { __typename?: 'InvoiceItem'; id: string } | null | undefined;
};

export type UpdatePaymentAppMutationVariables = Exact<{
  id: Scalars['uuid'];
  set?: InputMaybe<PaymentAppSetInput>;
  skipDeleteToken?: Scalars['Boolean'];
  skipNewToken?: Scalars['Boolean'];
  deleteTokens?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  newTokens?: InputMaybe<Array<PaymentAppTokenInsertInput> | PaymentAppTokenInsertInput>;
}>;

export type UpdatePaymentAppMutation = {
  __typename?: 'mutation_root';
  deletePaymentAppToken?:
    | { __typename?: 'PaymentAppTokenMutationResponse'; affected_rows: number }
    | null
    | undefined;
  insertPaymentAppToken?:
    | { __typename?: 'PaymentAppTokenMutationResponse'; affected_rows: number }
    | null
    | undefined;
  app?:
    | ({
        __typename?: 'PaymentApp';
        tokens: Array<{ __typename?: 'PaymentAppToken' } & PaymentAppTokenFragment>;
      } & PaymentAppFragment)
    | null
    | undefined;
};

export type UpdateProfileMutationVariables = Exact<{
  userId: Scalars['String'];
  _set?: InputMaybe<UserSetInput>;
}>;

export type UpdateProfileMutation = {
  __typename?: 'mutation_root';
  user?: ({ __typename?: 'User' } & UserFragment) | null | undefined;
};

export type UpsetAccountWalletsMutationVariables = Exact<{
  objects?: InputMaybe<Array<AccountWalletInsertInput> | AccountWalletInsertInput>;
}>;

export type UpsetAccountWalletsMutation = {
  __typename?: 'mutation_root';
  insertAccountWallet?:
    | {
        __typename?: 'AccountWalletMutationResponse';
        returning: Array<{ __typename?: 'AccountWallet' } & AccountWalletFragment>;
      }
    | null
    | undefined;
};

export type GetAccountQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetAccountQuery = {
  __typename?: 'query_root';
  account?: ({ __typename?: 'Account' } & AccountFragment) | null | undefined;
};

export type GetAccountWalletsQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type GetAccountWalletsQuery = {
  __typename?: 'query_root';
  wallets: Array<{ __typename?: 'AccountWallet' } & AccountWalletFragment>;
};

export type GetAddressBookQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressBookBoolExp>;
}>;

export type GetAddressBookQuery = {
  __typename?: 'query_root';
  pagination: {
    __typename?: 'AddressBookAggregate';
    aggregate?: { __typename?: 'AddressBookAggregateFields'; count: number } | null | undefined;
  };
  addressBook: Array<{ __typename?: 'AddressBook' } & AddressBookFragment>;
};

export type GetChainsQueryVariables = Exact<{ [key: string]: never }>;

export type GetChainsQuery = {
  __typename?: 'query_root';
  chains: Array<{ __typename?: 'Chain' } & ChainFragment>;
};

export type GetChainsAndTokensQueryVariables = Exact<{ [key: string]: never }>;

export type GetChainsAndTokensQuery = {
  __typename?: 'query_root';
  chains: Array<
    {
      __typename?: 'Chain';
      tokens: Array<
        {
          __typename?: 'Token';
          currency: { __typename?: 'Currency'; id: string; name: string };
        } & TokenFragment
      >;
    } & ChainFragment
  >;
};

export type GetContactForInvoiceQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContactBoolExp>;
}>;

export type GetContactForInvoiceQuery = {
  __typename?: 'query_root';
  contact: Array<{ __typename?: 'Contact' } & ContactFragment>;
};

export type GetContactsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContactBoolExp>;
}>;

export type GetContactsQuery = {
  __typename?: 'query_root';
  paging: {
    __typename?: 'ContactAggregate';
    items?: { __typename?: 'ContactAggregateFields'; count: number } | null | undefined;
  };
  contacts: Array<{ __typename?: 'Contact' } & ContactFragment>;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: 'query_root';
  countries: Array<{ __typename?: 'Country' } & CountryFragment>;
};

export type GetCurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrenciesQuery = {
  __typename?: 'query_root';
  currencies: Array<{ __typename?: 'Currency' } & CurrencyFragment>;
};

export type GetInvoiceByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetInvoiceByIdQuery = {
  __typename?: 'query_root';
  invoice?:
    | ({
        __typename?: 'Invoice';
        payment?: ({ __typename?: 'Payment' } & PaymentFragment) | null | undefined;
      } & InvoiceFragment)
    | null
    | undefined;
};

export type GetInvoicesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceBoolExp>;
}>;

export type GetInvoicesQuery = {
  __typename?: 'query_root';
  pagination: {
    __typename?: 'InvoiceAggregate';
    aggregate?: { __typename?: 'InvoiceAggregateFields'; count: number } | null | undefined;
  };
  invoices: Array<{ __typename?: 'Invoice' } & InvoiceFragment>;
};

export type GetMeQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type GetMeQuery = {
  __typename?: 'query_root';
  accounts: Array<
    {
      __typename?: 'Account';
      members: Array<{ __typename?: 'AccountMember' } & MemberFragment>;
    } & AccountFragment
  >;
  profile?: ({ __typename?: 'User' } & UserFragment) | null | undefined;
};

export type GetPaymentAppQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetPaymentAppQuery = {
  __typename?: 'query_root';
  app?:
    | ({
        __typename?: 'PaymentApp';
        tokens: Array<{ __typename?: 'PaymentAppToken' } & PaymentAppTokenFragment>;
      } & PaymentAppFragment)
    | null
    | undefined;
};

export type GetPaymentAppsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  accountId: Scalars['String'];
}>;

export type GetPaymentAppsQuery = {
  __typename?: 'query_root';
  pagination: {
    __typename?: 'PaymentAppAggregate';
    aggregate?: { __typename?: 'PaymentAppAggregateFields'; count: number } | null | undefined;
  };
  apps: Array<{ __typename?: 'PaymentApp' } & PaymentAppFragment>;
};

export type GetPaymentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentBoolExp>;
}>;

export type GetPaymentsQuery = {
  __typename?: 'query_root';
  pagination: {
    __typename?: 'PaymentAggregate';
    aggregate?: { __typename?: 'PaymentAggregateFields'; count: number } | null | undefined;
  };
  payments: Array<{ __typename?: 'Payment' } & PaymentFragment>;
};

export type GetTokensQueryVariables = Exact<{ [key: string]: never }>;

export type GetTokensQuery = {
  __typename?: 'query_root';
  tokens: Array<{ __typename?: 'Token' } & TokenFragment>;
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetUserByIdQuery = {
  __typename?: 'query_root';
  user?: ({ __typename?: 'User' } & UserFragment) | null | undefined;
};

export const AccountWalletFragmentDoc = gql`
  fragment AccountWallet on AccountWallet {
    id
    accountId
    chainId
    address
    createdAt
    updatedAt
  }
`;
export const ChainFragmentDoc = gql`
  fragment Chain on Chain {
    id
    name
    currencyId
    isTestnet
    updatedAt
    isLive
    createdAt
  }
`;
export const AddressBookFragmentDoc = gql`
  fragment AddressBook on AddressBook {
    id
    name
    chainId
    address
    createdAt
    updatedAt
    accountId
    chain {
      ...Chain
    }
  }
  ${ChainFragmentDoc}
`;
export const ContactFragmentDoc = gql`
  fragment Contact on Contact {
    id
    fullName
    companyName
    email
    addressFirst
    addressSecond
    city
    type
    postCode
    countryId
    department
    jobTitle
    description
    createdAt
    phoneNumber
    updatedAt
    accountId
  }
`;
export const CountryFragmentDoc = gql`
  fragment Country on Country {
    id
    name
    dialCode
    emoji
  }
`;
export const CurrencyFragmentDoc = gql`
  fragment Currency on Currency {
    id
    name
    priceUSD
    updatedAt
    isFiat
  }
`;
export const AccountFragmentDoc = gql`
  fragment Account on Account {
    id
    publicAddress
    useExternalWallet
    ownerId
    photoUrl
    primaryEmail
    secondEmail
    updatedAt
    name
    createdAt
  }
`;
export const MemberFragmentDoc = gql`
  fragment Member on AccountMember {
    id
    accountId
    role
    updatedAt
    userId
    createdAt
  }
`;
export const TokenFragmentDoc = gql`
  fragment Token on Token {
    id
    decimals
    currencyId
    updatedAt
    chainId
    isEnable
    chain {
      ...Chain
    }
  }
  ${ChainFragmentDoc}
`;
export const InvoiceItemFragmentDoc = gql`
  fragment InvoiceItem on InvoiceItem {
    id
    name
    quality
    price
    total
  }
`;
export const InvoiceFragmentDoc = gql`
  fragment Invoice on Invoice {
    id
    contractId
    createdAt
    customerId
    discount
    confirmAt
    dueAt
    isApproved
    isDeleted
    isDrafted
    isExpired
    isPaid
    note
    account {
      ...Account
      members {
        ...Member
      }
    }
    paymentId
    tax
    tokenId
    token {
      ...Token
      chain {
        ...Chain
      }
    }
    items {
      ...InvoiceItem
    }
    updatedAt
    vatId
    contact {
      id
      fullName
      email
    }
    customer {
      id
      name
      primaryEmail
      photoUrl
      ownerId
    }
  }
  ${AccountFragmentDoc}
  ${MemberFragmentDoc}
  ${TokenFragmentDoc}
  ${ChainFragmentDoc}
  ${InvoiceItemFragmentDoc}
`;
export const PaymentAppFragmentDoc = gql`
  fragment PaymentApp on PaymentApp {
    id
    extras
    createdAt
    homepage
    kind
    name
    logoUrl
    updatedAt
    whitelistedDomain
    accountId
    apiToken
    webhookUrl
    isEnabled
  }
`;
export const PaymentFragmentDoc = gql`
  fragment Payment on Payment {
    id
    appId
    cryptoPrice
    cryptoTokenId
    expiredAt
    fiatCurrencyId
    fiatPrice
    isFiatSource
    isLocked
    isValidated
    lockedAt
    orderDescription
    orderId
    receiverAddress
    status
    transactionId
    txHash
    accountId
    token {
      id
      decimals
      currency {
        id
        name
      }
      chain {
        id
        name
        currency {
          id
          name
        }
      }
    }
    invoice {
      ...Invoice
    }
    app {
      ...PaymentApp
    }
    token {
      ...Token
    }
  }
  ${InvoiceFragmentDoc}
  ${PaymentAppFragmentDoc}
  ${TokenFragmentDoc}
`;
export const PaymentAppTokenFragmentDoc = gql`
  fragment PaymentAppToken on PaymentAppToken {
    id
    appId
    tokenId
    token {
      id
      chainId
    }
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    fullName
    email
    createdAt
    companyRole
    companyName
    companyGstRegistration
    avatarUrl
    isBusiness
    phoneNumber
    updatedAt
    city
    countryId
    address
  }
`;
export const AddAddressBookDocument = gql`
  mutation addAddressBook($object: AddressBookInsertInput = {}) {
    AddressBook: insertAddressBookOne(object: $object) {
      ...AddressBook
    }
  }
  ${AddressBookFragmentDoc}
`;

export function useAddAddressBookMutation() {
  return Urql.useMutation<AddAddressBookMutation, AddAddressBookMutationVariables>(
    AddAddressBookDocument
  );
}
export const AddContactDocument = gql`
  mutation addContact($object: ContactInsertInput = {}) {
    Contact: insertContactOne(object: $object) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;

export function useAddContactMutation() {
  return Urql.useMutation<AddContactMutation, AddContactMutationVariables>(AddContactDocument);
}
export const ApproveInvoiceDocument = gql`
  mutation approveInvoice($invoiceId: String!, $isApproved: Boolean = false) {
    approveInvoice(args: { invoiceId: $invoiceId, isApproved: $isApproved }) {
      invoice {
        ...Invoice
        payment {
          ...Payment
        }
      }
    }
  }
  ${InvoiceFragmentDoc}
  ${PaymentFragmentDoc}
`;

export function useApproveInvoiceMutation() {
  return Urql.useMutation<ApproveInvoiceMutation, ApproveInvoiceMutationVariables>(
    ApproveInvoiceDocument
  );
}
export const CreateDraftInvoiceDocument = gql`
  mutation createDraftInvoice($accountId: String!) {
    createDraftInvoice(accountId: $accountId) {
      invoiceId
    }
  }
`;

export function useCreateDraftInvoiceMutation() {
  return Urql.useMutation<CreateDraftInvoiceMutation, CreateDraftInvoiceMutationVariables>(
    CreateDraftInvoiceDocument
  );
}
export const CreatePaymentAppDocument = gql`
  mutation createPaymentApp($accountId: String!) {
    createPaymentApp(args: { accountId: $accountId }) {
      paymentAppId
      app {
        ...PaymentApp
      }
    }
  }
  ${PaymentAppFragmentDoc}
`;

export function useCreatePaymentAppMutation() {
  return Urql.useMutation<CreatePaymentAppMutation, CreatePaymentAppMutationVariables>(
    CreatePaymentAppDocument
  );
}
export const DeleteAddressBookDocument = gql`
  mutation deleteAddressBook($id: bigint!) {
    deleteAddressBookByPk(id: $id) {
      ...AddressBook
    }
  }
  ${AddressBookFragmentDoc}
`;

export function useDeleteAddressBookMutation() {
  return Urql.useMutation<DeleteAddressBookMutation, DeleteAddressBookMutationVariables>(
    DeleteAddressBookDocument
  );
}
export const DeletePaymentAppDocument = gql`
  mutation deletePaymentApp($id: uuid!) {
    softDeletePaymentApp(id: $id) {
      id
    }
  }
`;

export function useDeletePaymentAppMutation() {
  return Urql.useMutation<DeletePaymentAppMutation, DeletePaymentAppMutationVariables>(
    DeletePaymentAppDocument
  );
}
export const LoginDocument = gql`
  mutation login($didToken: String!) {
    login(didToken: $didToken) {
      token
    }
  }
`;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
}
export const RemoveInvoiceDocument = gql`
  mutation removeInvoice($id: String!) {
    deleteInvoiceByPk(id: $id) {
      ...Invoice
    }
  }
  ${InvoiceFragmentDoc}
`;

export function useRemoveInvoiceMutation() {
  return Urql.useMutation<RemoveInvoiceMutation, RemoveInvoiceMutationVariables>(
    RemoveInvoiceDocument
  );
}
export const CreatePresignedUrlDocument = gql`
  mutation createPresignedUrl($type: UploadType = AVATAR) {
    requestUpload(contentType: "image/png", type: $type) {
      fields
      postUrl
      fileUrl
    }
  }
`;

export function useCreatePresignedUrlMutation() {
  return Urql.useMutation<CreatePresignedUrlMutation, CreatePresignedUrlMutationVariables>(
    CreatePresignedUrlDocument
  );
}
export const ResetPaymentAppTokenDocument = gql`
  mutation resetPaymentAppToken($id: uuid!) {
    resetPaymentAppToken(id: $id) {
      appId
      app {
        ...PaymentApp
      }
    }
  }
  ${PaymentAppFragmentDoc}
`;

export function useResetPaymentAppTokenMutation() {
  return Urql.useMutation<ResetPaymentAppTokenMutation, ResetPaymentAppTokenMutationVariables>(
    ResetPaymentAppTokenDocument
  );
}
export const SendInvoiceDocument = gql`
  mutation sendInvoice($id: String!) {
    sendInvoice(invoiceId: $id) {
      invoiceId
    }
  }
`;

export function useSendInvoiceMutation() {
  return Urql.useMutation<SendInvoiceMutation, SendInvoiceMutationVariables>(SendInvoiceDocument);
}
export const UpdateAccountDocument = gql`
  mutation updateAccount($id: String!, $_set: AccountSetInput = {}) {
    account: updateAccountByPk(pk_columns: { id: $id }, _set: $_set) {
      ...Account
    }
  }
  ${AccountFragmentDoc}
`;

export function useUpdateAccountMutation() {
  return Urql.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(
    UpdateAccountDocument
  );
}
export const UpdateAddressBookDocument = gql`
  mutation updateAddressBook($_set: AddressBookSetInput = {}, $id: bigint!) {
    updateAddressBookByPk(_set: $_set, pk_columns: { id: $id }) {
      ...AddressBook
    }
  }
  ${AddressBookFragmentDoc}
`;

export function useUpdateAddressBookMutation() {
  return Urql.useMutation<UpdateAddressBookMutation, UpdateAddressBookMutationVariables>(
    UpdateAddressBookDocument
  );
}
export const UpdateContractDocument = gql`
  mutation updateContract($_set: ContactSetInput = {}, $id: uuid!) {
    updateContactByPk(_set: $_set, pk_columns: { id: $id }) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;

export function useUpdateContractMutation() {
  return Urql.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(
    UpdateContractDocument
  );
}
export const UpdateInvoiceDocument = gql`
  mutation updateInvoice(
    $invoiceId: String!
    $invoiceSetInput: InvoiceSetInput = {}
    $itemInputs: [InvoiceItemInsertInput!] = {}
    $itemDeleteIds: [uuid!] = []
  ) {
    deleteInvoiceItem(where: { id: { _in: $itemDeleteIds } }) {
      affected_rows
    }
    insertInvoiceItem(
      objects: $itemInputs
      onConflict: { constraint: InvoiceItem_pkey, update_columns: [name, price, quality] }
    ) {
      affected_rows
    }
    invoice: updateInvoiceByPk(pk_columns: { id: $invoiceId }, _set: $invoiceSetInput) {
      ...Invoice
    }
  }
  ${InvoiceFragmentDoc}
`;

export function useUpdateInvoiceMutation() {
  return Urql.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(
    UpdateInvoiceDocument
  );
}
export const UpdateInvoiceItemDocument = gql`
  mutation updateInvoiceItem($id: [uuid!], $_set: InvoiceItemSetInput) {
    updateInvoiceItem(where: { id: { _in: $id } }, _set: $_set) {
      affected_rows
    }
  }
`;

export function useUpdateInvoiceItemMutation() {
  return Urql.useMutation<UpdateInvoiceItemMutation, UpdateInvoiceItemMutationVariables>(
    UpdateInvoiceItemDocument
  );
}
export const UpdateInvoiceItemByPkDocument = gql`
  mutation updateInvoiceItemByPk($id: uuid!, $_set: InvoiceItemSetInput) {
    updateInvoiceItemByPk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export function useUpdateInvoiceItemByPkMutation() {
  return Urql.useMutation<UpdateInvoiceItemByPkMutation, UpdateInvoiceItemByPkMutationVariables>(
    UpdateInvoiceItemByPkDocument
  );
}
export const UpdatePaymentAppDocument = gql`
  mutation updatePaymentApp(
    $id: uuid!
    $set: PaymentAppSetInput = {}
    $skipDeleteToken: Boolean! = true
    $skipNewToken: Boolean! = true
    $deleteTokens: [String!] = ""
    $newTokens: [PaymentAppTokenInsertInput!] = {}
  ) {
    deletePaymentAppToken(where: { tokenId: { _in: $deleteTokens }, appId: { _eq: $id } })
      @skip(if: $skipDeleteToken) {
      affected_rows
    }
    insertPaymentAppToken(objects: $newTokens) @skip(if: $skipNewToken) {
      affected_rows
    }
    app: updatePaymentAppByPk(pk_columns: { id: $id }, _set: $set) {
      ...PaymentApp
      tokens {
        ...PaymentAppToken
      }
    }
  }
  ${PaymentAppFragmentDoc}
  ${PaymentAppTokenFragmentDoc}
`;

export function useUpdatePaymentAppMutation() {
  return Urql.useMutation<UpdatePaymentAppMutation, UpdatePaymentAppMutationVariables>(
    UpdatePaymentAppDocument
  );
}
export const UpdateProfileDocument = gql`
  mutation updateProfile($userId: String!, $_set: UserSetInput = {}) {
    user: updateUserByPk(pk_columns: { id: $userId }, _set: $_set) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

export function useUpdateProfileMutation() {
  return Urql.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(
    UpdateProfileDocument
  );
}
export const UpsetAccountWalletsDocument = gql`
  mutation upsetAccountWallets($objects: [AccountWalletInsertInput!] = {}) {
    insertAccountWallet(
      objects: $objects
      onConflict: { constraint: Wallet_accountId_chainId_key, update_columns: address }
    ) {
      returning {
        ...AccountWallet
      }
    }
  }
  ${AccountWalletFragmentDoc}
`;

export function useUpsetAccountWalletsMutation() {
  return Urql.useMutation<UpsetAccountWalletsMutation, UpsetAccountWalletsMutationVariables>(
    UpsetAccountWalletsDocument
  );
}
export const GetAccountDocument = gql`
  query getAccount($id: String!) {
    account: accountByPk(id: $id) {
      ...Account
    }
  }
  ${AccountFragmentDoc}
`;

export function useGetAccountQuery(
  options: Omit<Urql.UseQueryArgs<GetAccountQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAccountQuery, GetAccountQueryVariables>({
    query: GetAccountDocument,
    ...options,
  });
}
export const GetAccountWalletsDocument = gql`
  query getAccountWallets($accountId: String!) {
    wallets: accountWallet(where: { accountId: { _eq: $accountId } }) {
      ...AccountWallet
    }
  }
  ${AccountWalletFragmentDoc}
`;

export function useGetAccountWalletsQuery(
  options: Omit<Urql.UseQueryArgs<GetAccountWalletsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAccountWalletsQuery, GetAccountWalletsQueryVariables>({
    query: GetAccountWalletsDocument,
    ...options,
  });
}
export const GetAddressBookDocument = gql`
  query getAddressBook($limit: Int = 10, $offset: Int = 0, $where: AddressBookBoolExp = {}) {
    pagination: addressBookAggregate(where: $where) {
      aggregate {
        count
      }
    }
    addressBook(limit: $limit, offset: $offset, where: $where, orderBy: { createdAt: DESC }) {
      ...AddressBook
    }
  }
  ${AddressBookFragmentDoc}
`;

export function useGetAddressBookQuery(
  options?: Omit<Urql.UseQueryArgs<GetAddressBookQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAddressBookQuery, GetAddressBookQueryVariables>({
    query: GetAddressBookDocument,
    ...options,
  });
}
export const GetChainsDocument = gql`
  query getChains {
    chains: chain {
      ...Chain
    }
  }
  ${ChainFragmentDoc}
`;

export function useGetChainsQuery(
  options?: Omit<Urql.UseQueryArgs<GetChainsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetChainsQuery, GetChainsQueryVariables>({
    query: GetChainsDocument,
    ...options,
  });
}
export const GetChainsAndTokensDocument = gql`
  query getChainsAndTokens {
    chains: chain {
      ...Chain
      tokens {
        ...Token
        currency {
          id
          name
        }
      }
    }
  }
  ${ChainFragmentDoc}
  ${TokenFragmentDoc}
`;

export function useGetChainsAndTokensQuery(
  options?: Omit<Urql.UseQueryArgs<GetChainsAndTokensQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetChainsAndTokensQuery, GetChainsAndTokensQueryVariables>({
    query: GetChainsAndTokensDocument,
    ...options,
  });
}
export const GetContactForInvoiceDocument = gql`
  query getContactForInvoice($limit: Int = 10, $where: ContactBoolExp = {}) {
    contact(limit: $limit, where: $where, orderBy: { createdAt: DESC }) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;

export function useGetContactForInvoiceQuery(
  options?: Omit<Urql.UseQueryArgs<GetContactForInvoiceQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetContactForInvoiceQuery, GetContactForInvoiceQueryVariables>({
    query: GetContactForInvoiceDocument,
    ...options,
  });
}
export const GetContactsDocument = gql`
  query getContacts($limit: Int = 10, $offset: Int = 0, $where: ContactBoolExp = {}) {
    paging: contactAggregate(where: $where) {
      items: aggregate {
        count
      }
    }
    contacts: contact(limit: $limit, offset: $offset, where: $where, orderBy: { updatedAt: DESC }) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;

export function useGetContactsQuery(
  options?: Omit<Urql.UseQueryArgs<GetContactsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetContactsQuery, GetContactsQueryVariables>({
    query: GetContactsDocument,
    ...options,
  });
}
export const GetCountriesDocument = gql`
  query getCountries {
    countries: country {
      ...Country
    }
  }
  ${CountryFragmentDoc}
`;

export function useGetCountriesQuery(
  options?: Omit<Urql.UseQueryArgs<GetCountriesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCountriesQuery, GetCountriesQueryVariables>({
    query: GetCountriesDocument,
    ...options,
  });
}
export const GetCurrenciesDocument = gql`
  query getCurrencies {
    currencies: currency {
      ...Currency
    }
  }
  ${CurrencyFragmentDoc}
`;

export function useGetCurrenciesQuery(
  options?: Omit<Urql.UseQueryArgs<GetCurrenciesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>({
    query: GetCurrenciesDocument,
    ...options,
  });
}
export const GetInvoiceByIdDocument = gql`
  query getInvoiceById($id: String!) {
    invoice: invoiceByPk(id: $id) {
      ...Invoice
      payment {
        ...Payment
      }
    }
  }
  ${InvoiceFragmentDoc}
  ${PaymentFragmentDoc}
`;

export function useGetInvoiceByIdQuery(
  options: Omit<Urql.UseQueryArgs<GetInvoiceByIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>({
    query: GetInvoiceByIdDocument,
    ...options,
  });
}
export const GetInvoicesDocument = gql`
  query getInvoices($limit: Int = 10, $offset: Int = 0, $where: InvoiceBoolExp = {}) {
    pagination: invoiceAggregate(where: $where) {
      aggregate {
        count
      }
    }
    invoices: invoice(limit: $limit, offset: $offset, orderBy: { updatedAt: DESC }, where: $where) {
      ...Invoice
    }
  }
  ${InvoiceFragmentDoc}
`;

export function useGetInvoicesQuery(
  options?: Omit<Urql.UseQueryArgs<GetInvoicesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>({
    query: GetInvoicesDocument,
    ...options,
  });
}
export const GetMeDocument = gql`
  query getMe($userId: String!) {
    accounts: account {
      ...Account
      members(limit: 1, where: { userId: { _eq: $userId } }) {
        ...Member
      }
    }
    profile: userByPk(id: $userId) {
      ...User
    }
  }
  ${AccountFragmentDoc}
  ${MemberFragmentDoc}
  ${UserFragmentDoc}
`;

export function useGetMeQuery(options: Omit<Urql.UseQueryArgs<GetMeQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMeQuery, GetMeQueryVariables>({ query: GetMeDocument, ...options });
}
export const GetPaymentAppDocument = gql`
  query getPaymentApp($id: uuid!) {
    app: paymentAppByPk(id: $id) {
      ...PaymentApp
      tokens {
        ...PaymentAppToken
      }
    }
  }
  ${PaymentAppFragmentDoc}
  ${PaymentAppTokenFragmentDoc}
`;

export function useGetPaymentAppQuery(
  options: Omit<Urql.UseQueryArgs<GetPaymentAppQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPaymentAppQuery, GetPaymentAppQueryVariables>({
    query: GetPaymentAppDocument,
    ...options,
  });
}
export const GetPaymentAppsDocument = gql`
  query getPaymentApps($limit: Int = 20, $offset: Int = 0, $accountId: String!) {
    pagination: paymentAppAggregate(where: { accountId: { _eq: $accountId } }) {
      aggregate {
        count
      }
    }
    apps: paymentApp(
      limit: $limit
      offset: $offset
      orderBy: { kind: DESC, createdAt: ASC }
      where: { accountId: { _eq: $accountId } }
    ) {
      ...PaymentApp
    }
  }
  ${PaymentAppFragmentDoc}
`;

export function useGetPaymentAppsQuery(
  options: Omit<Urql.UseQueryArgs<GetPaymentAppsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPaymentAppsQuery, GetPaymentAppsQueryVariables>({
    query: GetPaymentAppsDocument,
    ...options,
  });
}
export const GetPaymentsDocument = gql`
  query getPayments($limit: Int = 20, $offset: Int = 0, $where: PaymentBoolExp = {}) {
    pagination: paymentAggregate(where: $where) {
      aggregate {
        count
      }
    }
    payments: payment(limit: $limit, offset: $offset, where: $where) {
      ...Payment
    }
  }
  ${PaymentFragmentDoc}
`;

export function useGetPaymentsQuery(
  options?: Omit<Urql.UseQueryArgs<GetPaymentsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>({
    query: GetPaymentsDocument,
    ...options,
  });
}
export const GetTokensDocument = gql`
  query getTokens {
    tokens: token {
      ...Token
    }
  }
  ${TokenFragmentDoc}
`;

export function useGetTokensQuery(
  options?: Omit<Urql.UseQueryArgs<GetTokensQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetTokensQuery, GetTokensQueryVariables>({
    query: GetTokensDocument,
    ...options,
  });
}
export const GetUserByIdDocument = gql`
  query getUserById($id: String!) {
    user: userByPk(id: $id) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

export function useGetUserByIdQuery(
  options: Omit<Urql.UseQueryArgs<GetUserByIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>({
    query: GetUserByIdDocument,
    ...options,
  });
}
