import '@fontsource/inter';
import { ChakraProvider } from '@chakra-ui/react';
import { customTheme } from 'theme';
import Routers from 'pages/Routers';
import { WagmiConfig } from 'wagmi';
import { AppProvider } from './AppContext';
import 'i18n/config';
import { client } from 'chains/wagmi';

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <AppProvider>
        <WagmiConfig client={client}>
          <Routers />
        </WagmiConfig>
      </AppProvider>
    </ChakraProvider>
  );
}

export default App;
