import { createContext, useState, useCallback, useMemo, ReactNode } from 'react';
import { useMagic } from 'hooks';
import { MagicUserMetadata } from 'magic-sdk';
import * as TokenStorage from 'utils/TokenStorage';
import { Provider } from 'urql';

import { makeClient } from 'client/configure';
import { AccountFragment, MemberFragment, UserFragment } from 'client';
import { DEFAULT_CHAIN_ID } from 'constants/configs';

interface MeData {
  currentAccount: AccountFragment & {
    members: MemberFragment;
  };
  profile: UserFragment;
  accounts: AccountFragment &
    {
      members: MemberFragment;
    }[];
}

interface AppContextType {
  isAuthenticated: boolean;
  isInitializing: boolean;
  userMetadata?: MagicUserMetadata;
  me?: MeData;
  currency: string;
  chainId: number;
  switchChain: (chainId: number) => void;
  changeCurrency: (currency: string) => void;
  setUserMetadata: (userMetadata?: MagicUserMetadata) => void;
  setToken: (token?: string) => void;
  loginWithEmail: (email: string) => Promise<string | null>;
  logout: () => Promise<void>;
  setIsInitializing: (isInitializing: boolean) => void;
  setMeData: (data?: MeData) => void;
}

export const AppContext = createContext<AppContextType>({
  isAuthenticated: false,
  chainId: DEFAULT_CHAIN_ID,
  isInitializing: false,
  switchChain(chainId) {},
  setMeData(data?) {},
  loginWithEmail: async () => null,
  logout: async () => {},
  setToken: () => {},
  setUserMetadata: () => {},
  setIsInitializing: () => {},
  currency: 'usd',
  changeCurrency: () => {},
});

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const [isInitializing, setIsInitializing] = useState(true);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [me, setMe] = useState<MeData | undefined>(undefined);
  const [userMetadata, setUserMetadata] = useState<MagicUserMetadata | undefined>(undefined);
  const { magic } = useMagic(1);
  const [currency, setCurrency] = useState(localStorage.getItem('currency') || 'usd');
  const saveChainId = localStorage.getItem('chain-id') || DEFAULT_CHAIN_ID;
  const [chainId, setChainId] = useState(+saveChainId);

  const switchChain = useCallback(
    (chainId: number) => {
      localStorage.setItem('chain-id', `${chainId}`);
      setChainId(chainId);
    },
    [setChainId]
  );

  const changeCurrency = useCallback((currency: string) => {
    localStorage.setItem('currency', currency);
    setCurrency(currency);
  }, []);

  const loginWithEmail = useCallback(
    async (email: string, redirectURI?: string) => {
      if (userMetadata) {
        if (userMetadata.email === email) {
          const idToken = await magic.user.getIdToken();
          return idToken;
        } else {
          await magic.user.logout();
        }
      }
      const didToken = await magic.auth.loginWithMagicLink({
        email,
        redirectURI,
      });
      const newUserMetadata = await magic.user.getMetadata();
      setUserMetadata(newUserMetadata);
      return didToken;
    },
    [magic.auth, magic.user, userMetadata]
  );

  const setCustomToken = useCallback((token?: string) => {
    if (token) {
      TokenStorage.saveToken(token);
      setToken(token);
    } else {
      TokenStorage.removeToken();
      setToken(undefined);
    }
  }, []);

  const logout = useCallback(async () => {
    await magic.user.logout();
    setCustomToken(undefined);
  }, [magic.user, setCustomToken]);

  const client = useMemo(() => {
    return makeClient(logout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMetadata]);

  const isAuthenticated = useMemo(() => !!token, [token]);

  return (
    <AppContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        isInitializing,
        userMetadata,
        me,
        currency,
        chainId,
        switchChain,
        changeCurrency,
        setMeData: setMe,
        loginWithEmail,
        logout,
        setToken: setCustomToken,
        setUserMetadata,
        setIsInitializing,
      }}>
      <Provider value={client}>{children}</Provider>
    </AppContext.Provider>
  );
};
