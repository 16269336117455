import { Navigate, useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { lazy, ReactNode, Suspense } from 'react';
import { Center, Spinner } from '@chakra-ui/react';

import SidebarLayout from 'components/layouts/SidebarLayout';
import PageNotFoundView from './PageNotFoundView';
import Transactions from './Transactions';
import { useApp } from 'hooks';
import Splash from './Splash';
import FullScreenLayout from 'components/layouts/FullscreenLayout';

type RouteLoaderProps = {
  children: ReactNode;
};

const Overview = lazy(() => import('./Overview'));
const Demo = lazy(() => import('./Demo/Index'));
const ListInvoice = lazy(() => import('./Invoices/List'));
const CreateInvoice = lazy(() => import('./Invoices/Create'));
const EditInvoice = lazy(() => import('./Invoices/Edit'));
const DetailsInvoice = lazy(() => import('./Invoices/Details'));
const Login = lazy(() => import('./Login'));
const Contacts = lazy(() => import('./Contacts'));
const Settings = lazy(() => import('./Settings'));
// const SettingsList = lazy(() => import('./Settings/List'));
// const SettingsDetails = lazy(() => import('./Settings/Details'));
const AppList = lazy(() => import('./Apps/List'));
const AppDetails = lazy(() => import('./Apps/Details'));

const PaymentOnOff = lazy(() => import('./Payments/OnOff'));
const PaymentInStore = lazy(() => import('./Payments/InStore'));

const DemoInvoiceDetails = lazy(() => import('./Demo/InvoiceDetails'));
const Wallet = lazy(() => import('./Wallet/Wallet'));

const RouteLoader = ({ children }: RouteLoaderProps) => {
  return (
    <Suspense
      fallback={
        <Center w="100%" h="100%">
          <Spinner />
        </Center>
      }>
      {children}
    </Suspense>
  );
};

const Routers = () => {
  const { isInitializing } = useApp();
  const mainRoutes: RouteObject = {
    path: '/',
    element: <SidebarLayout />,
    children: [
      { path: '*', element: <Navigate to="/404" /> },
      {
        path: '/',
        element: (
          <RouteLoader>
            <Overview />
          </RouteLoader>
        ),
      },
      {
        path: '/wallet',
        element: (
          <RouteLoader>
            <Wallet />
          </RouteLoader>
        ),
      },
      {
        path: '/contacts',
        element: (
          <RouteLoader>
            <Contacts />
          </RouteLoader>
        ),
      },
      {
        path: '/settings',
        element: (
          <RouteLoader>
            <Settings />
          </RouteLoader>
        ),
      },
      {
        path: '/transactions',
        element: (
          <RouteLoader>
            <Transactions />
          </RouteLoader>
        ),
      },
      {
        path: '/invoices/:type',
        element: (
          <RouteLoader>
            <ListInvoice />
          </RouteLoader>
        ),
      },
      {
        path: '/invoice/:id',
        element: (
          <RouteLoader>
            <DetailsInvoice />
          </RouteLoader>
        ),
      },
      {
        path: '/invoice/new',
        element: (
          <RouteLoader>
            <CreateInvoice />
          </RouteLoader>
        ),
      },
      {
        path: '/payments',
        element: (
          <RouteLoader>
            <PaymentInStore />
          </RouteLoader>
        ),
      },
      {
        path: '/payments/one-off',
        element: (
          <RouteLoader>
            <PaymentOnOff />
          </RouteLoader>
        ),
      },
      {
        path: '/payments/apps',
        element: (
          <RouteLoader>
            <AppList />
          </RouteLoader>
        ),
      },
      {
        path: '/app/:id',
        element: (
          <RouteLoader>
            <AppDetails />
          </RouteLoader>
        ),
      },
    ],
  };

  const fullScreenProtectedRoutes: RouteObject = {
    path: '/',
    element: <FullScreenLayout isCheckLogin />,
    children: [
      { path: '*', element: <Navigate to="/404" /> },
      {
        path: '/invoice/edit/:id',
        element: (
          <RouteLoader>
            <EditInvoice />
          </RouteLoader>
        ),
      },
    ],
  };

  const authRoutes: RouteObject = {
    path: 'auth',
    element: <FullScreenLayout />,
    children: [
      { path: '*', element: <Navigate to="/404" /> },
      {
        path: 'login',
        element: (
          <RouteLoader>
            <Login />
          </RouteLoader>
        ),
      },
    ],
  };
  const NotFoundRoutes: RouteObject = {
    path: '404',
    element: <PageNotFoundView />,
  };

  const demoRoutes: RouteObject = {
    path: '/demo',
    element: <SidebarLayout />,
    children: [
      { path: '*', element: <Navigate to="/404" /> },
      {
        path: '/demo',
        element: (
          <RouteLoader>
            <Demo />
          </RouteLoader>
        ),
      },
      {
        path: '/demo/invoice',
        element: (
          <RouteLoader>
            <DemoInvoiceDetails />
          </RouteLoader>
        ),
      },
    ],
  };

  const routing = useRoutes([
    NotFoundRoutes,
    mainRoutes,
    fullScreenProtectedRoutes,
    authRoutes,
    demoRoutes,
  ]);
  if (isInitializing) {
    return <Splash />;
  }

  return <>{routing}</>;
};

export default Routers;
