import { useToast as useToastUI } from '@chakra-ui/react';
import { useCallback } from 'react';
import { CombinedError } from 'urql';

interface InputToast {
  title?: string;
  description?: string;
  duration?: number;
  id?: string;
}

const useToast = () => {
  const toast = useToastUI();

  const successToast = useCallback(
    ({ title, description, duration = 3000, id }: InputToast) => {
      toast({
        title: title || 'Success',
        description: description,
        position: 'top',
        status: 'success',
        duration: duration,
        isClosable: true,
        id,
      });
    },
    [toast]
  );

  const errorToast = useCallback(
    ({ title, description, duration = 3000, id }: InputToast) => {
      toast({
        title: title || 'Ops!',
        description: description,
        position: 'top',
        status: 'error',
        duration: duration,
        isClosable: true,
        id,
      });
    },
    [toast]
  );

  const combinedErrorToast = useCallback(
    (error: CombinedError) => {
      let description = 'Some thing went wrong';
      if (error.graphQLErrors) {
        const isEmail = error.graphQLErrors.some((e) => e.message.includes('check_email'));
        const isLength = error.graphQLErrors.some((e) => e.message.includes('check_length'));
        if (isEmail) {
          description = 'Email is invalid';
        } else if (isLength) {
          description = 'Please check the data, length is invalid';
        }
      }
      errorToast({
        title: 'Ops!',
        description: description,
      });
    },
    [errorToast]
  );

  return { successToast, errorToast, combinedErrorToast };
};

export default useToast;
