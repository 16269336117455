import {
  createClient,
  dedupExchange,
  fetchExchange,
  Exchange,
  makeOperation,
  subscriptionExchange,
  errorExchange,
} from 'urql';
import { retryExchange } from '@urql/exchange-retry';
import { refocusExchange } from '@urql/exchange-refocus';
import { cacheExchange } from '@urql/exchange-graphcache';
import { createClient as createWSClient } from 'graphql-ws';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import { authExchange } from '@urql/exchange-auth';
import schema from './schema.json';
import * as TokenStorage from 'utils/TokenStorage';
const HASURA_GRAPHQL_ENDPOINT = process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT || '';

type AuthState = {
  token: string;
};

export const makeClient = (logout: () => Promise<void>) => {
  const wsClient = createWSClient({
    url: HASURA_GRAPHQL_ENDPOINT.replace('https', 'wss').replace('http', 'ws'),
    lazy: true,
  });

  const exchanges: Exchange[] = [
    dedupExchange,
    requestPolicyExchange({}),
    refocusExchange(),
    cacheExchange({
      // @ts-ignore
      schema,
      keys: {
        ContactAggregate: (data) => null,
        ContactAggregateFields: (data) => null,
        InvoiceAggregate: (data) => null,
        InvoiceAggregateFields: (data) => null,
        PaymentAppAggregate: (data) => null,
        PaymentAppAggregateFields: (data) => null,
        AddressBookAggregate: (data) => null,
        AddressBookAggregateFields: (data) => null,
      },
      updates: {
        Mutation: {
          // updateWhitelistByPk: (result, args, cache, info) => {
          //   // @ts-ignore
          //   if (args?._set?.isDelete) {
          //     cache
          //       .inspectFields('Query')
          //       .filter((field) => field.fieldName === 'whitelists')
          //       .forEach((field) => {
          //         cache.updateQuery<GetWhitelistQuery, GetWhitelistQueryVariables>(
          //           {
          //             query: GetWhitelistDocument,
          //           },
          //           (data) => {
          //             if (!!data && !!data.whitelists) {
          //               data.whitelists = data?.whitelists.filter(
          //                 (whitelist) => whitelist.id !== args.id
          //               );
          //             }
          //             return data;
          //           }
          //         );
          //       });
          //   }
          // },
          // insertWhitelistOne: (result, args, cache, info) => {
          //   cache.updateQuery<GetWhitelistQuery, GetWhitelistQueryVariables>(
          //     { query: GetWhitelistDocument },
          //     (data) => {
          //       if (!!data && !!data.whitelists) {
          //         // @ts-ignore
          //         data?.whitelists.push(result.whitelist);
          //       }
          //       return data;
          //     }
          //   );
          // },
          deleteInvoiceByPk: (result, args, cache, info) => {
            cache.invalidate({
              __typename: 'Invoice',
              id: `${args.id}`,
            });
          },
          softDeletePaymentApp: (result, args, cache, info) => {
            cache.invalidate({
              __typename: 'PaymentApp',
              id: `${args.id}`,
            });
          },
        },
      },
    }),
    retryExchange({
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: (error) => {
        return !!(error.graphQLErrors.length > 0 || error.networkError);
      },
    }),
    errorExchange({
      onError: async (error) => {
        const isAuthError = error.graphQLErrors.some((e) => e.extensions?.code === 'invalid-jwt');
        if (isAuthError) {
          logout();
        }
      },
    }),
    authExchange<AuthState>({
      addAuthToOperation: ({ authState, operation }) => {
        if (!authState || !authState.token) {
          return operation;
        }

        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: `Bearer ${authState.token}`,
            },
          },
        });
      },
      willAuthError: ({ operation, authState }) => {
        if (!authState) {
          return !(
            operation.kind === 'mutation' &&
            operation.query.definitions.some((definition) => {
              return (
                definition.kind === 'OperationDefinition' &&
                definition.selectionSet.selections.some((node) => {
                  return node.kind === 'Field' && node.name.value === 'login';
                })
              );
            })
          );
        }
        return false;
      },
      didAuthError: ({ error }) => {
        return error.graphQLErrors.some((e) => e.extensions?.code === 'invalid-jwt');
      },
      getAuth: async ({ authState, mutate }) => {
        if (!authState) {
          const token = TokenStorage.getToken();
          return token ? { token } : null;
        }

        return null;
      },
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(operation) {
        return {
          subscribe: (sink) => {
            const dispose = wsClient.subscribe(operation, sink);
            return {
              unsubscribe: dispose,
            };
          },
        };
      },
    }),
  ];

  return createClient({
    url: HASURA_GRAPHQL_ENDPOINT,
    suspense: false,
    exchanges,
  });
};
