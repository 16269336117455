import { getMagic, getMagicProvider, getConfig } from 'chains';
import { useMemo } from 'react';

const useMagic = (chainId: number) => {
  const magic = useMemo(() => getMagic(chainId), [chainId]);
  const provider = useMemo(() => getMagicProvider(chainId), [chainId]);
  const config = getConfig(chainId);
  return { magic, provider, currency: config?.currency };
};

export default useMagic;
