import { Image, ImageProps, Tooltip } from '@chakra-ui/react';
import { ColorMix } from 'theme/types';

interface CurrencyIconProps extends ImageProps {
  id?: string;
}

const CurrencyIcon = ({ id: symbol, ...props }: CurrencyIconProps) => {
  const uri = symbol ? `/images/currencies/${symbol}.png` : '/images/currencies/undefined.png';
  return (
    <Tooltip label={symbol}>
      <Image
        title={symbol}
        boxSize={'40px'}
        src={uri}
        borderRadius="full"
        backgroundColor={ColorMix.bgContent}
        {...props}
      />
    </Tooltip>
  );
};

export default CurrencyIcon;
