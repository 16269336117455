import {
  Avatar,
  Text,
  Icon,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  MenuDivider,
  VStack,
  useColorMode,
  Spinner,
} from '@chakra-ui/react';
import { useGetUserByIdQuery } from 'client';
import { useApp } from 'hooks';
import { useCallback, useState } from 'react';
import { HiMoon, HiOutlineSun, HiOutlineArrowCircleLeft, HiOutlineUser } from 'react-icons/hi';
import { ColorMix, TextStyle } from 'theme/types';

const UserProfile = () => {
  const { userId, logout } = useApp();
  const { colorMode, toggleColorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const [data] = useGetUserByIdQuery({
    variables: { id: `${userId}` },
    pause: !userId,
  });
  const profileName = data.data?.user?.fullName || 'unknown';
  const email = data.data?.user?.email || '';
  const avatarUri = data.data?.user?.avatarUrl;

  const btnLogout = useCallback(() => {
    setLoading(true);
    logout()
      .then()
      .finally(() => {
        setLoading(false);
      });
  }, [logout]);

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton>
            <Avatar boxSize={10} name={profileName} src={avatarUri || undefined} />
          </MenuButton>

          <MenuList textStyle={TextStyle.ParagraphSmall} fontWeight="medium">
            <MenuItem
              _groupHover={{ bg: ColorMix.bgItem }}
              _hover={{ bg: ColorMix.bgItem }}
              isFocusable={false}
              alignItems="center"
              justifyContent="center">
              <VStack spacing={1}>
                <Avatar boxSize={10} name={profileName} src={avatarUri || undefined} />
                <Text textStyle={TextStyle.ParagraphSmall}>{profileName}</Text>
                <Text textStyle={TextStyle.ParagraphXSmall}>{email}</Text>
              </VStack>
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={toggleColorMode}>
              <Icon as={colorMode !== 'dark' ? HiOutlineSun : HiMoon} mr={4} w={5} h={5} />
              <span>{colorMode === 'dark' ? 'Turn off Dark mode' : 'Turn on Dark mode'}</span>
            </MenuItem>
            <MenuItem>
              <Icon as={HiOutlineUser} mr={4} w={5} h={5} />
              <span>Account</span>
            </MenuItem>
            <MenuItem onClick={btnLogout} disabled={loading} color="red.500">
              <Icon as={loading ? Spinner : HiOutlineArrowCircleLeft} mr={4} w={5} h={5} />
              <span>Sign out</span>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default UserProfile;
