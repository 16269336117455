import { useCallback, useEffect, memo } from 'react';
import { Box, Spinner, VStack, Text, Button } from '@chakra-ui/react';
import Logo from 'components/Logo';
import * as TokenStorage from 'utils/TokenStorage';
import { useGetMeQuery, useLoginMutation } from 'client';
import { useApp, useMagic } from 'hooks';

const Splash = () => {
  const { setToken, setUserMetadata, userId, setIsInitializing, setMeData } = useApp();
  const [{ error: errLogin, fetching }, loginWithServer] = useLoginMutation();

  const [{ data, error: errMe }, getMe] = useGetMeQuery({
    pause: !userId,
    variables: {
      userId: `${userId}`,
    },
    requestPolicy: 'network-only',
  });
  const { magic } = useMagic(1);

  const error = errLogin || errMe;

  useEffect(() => {
    console.log('Data me stale', data);

    if (data) {
      const ownerAccount = data.accounts.find((i) => i.ownerId === userId);
      setMeData({
        profile: data.profile!,
        // @ts-ignore
        accounts: data.account,
        // @ts-ignore
        currentAccount: ownerAccount,
      });
      setIsInitializing(false);
    }
    //setIsInitializing(false)
  }, [data, setIsInitializing, setMeData, userId]);

  useEffect(() => {
    (async () => {
      const token = TokenStorage.getToken();
      const isLogin = await magic.user.isLoggedIn();
      console.log('magic isLogin', isLogin);
      if (isLogin) {
        const userMetadata = await magic.user.getMetadata();
        setUserMetadata(userMetadata);
        if (token) {
          console.log('token already exists: ', token);
          setToken(token);
          //setIsInitializing(false);
        } else {
          const idToken = await magic.user.getIdToken();
          const result = await loginWithServer(
            { didToken: idToken },
            { requestPolicy: 'network-only' }
          );
          setToken(result.data?.login?.token);
          //setIsInitializing(false);
        }
        await getMe();
      } else {
        setToken(undefined);
        setUserMetadata(undefined);
        setIsInitializing(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retry = useCallback(() => {
    (async () => {
      const idToken = await magic.user.getIdToken();
      const result = await loginWithServer(
        { didToken: idToken },
        { requestPolicy: 'network-only' }
      );
      setToken(result.data?.login?.token);
      setIsInitializing(false);
    })();
  }, [loginWithServer, magic.user, setIsInitializing, setToken]);

  return (
    <Box minH="100vh" display={'flex'} justifyContent="center" alignItems={'center'}>
      <VStack spacing={'6'}>
        <Logo height="12" />
        <Spinner size={'lg'} />
        {error && (
          <VStack spacing={'10'}>
            <Text>
              {error?.networkError ? 'Can not connect to server' : 'The server could not execute'}
            </Text>
            <Button isLoading={fetching} onClick={retry}>
              Retry
            </Button>
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

export default memo(Splash);
