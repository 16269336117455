import { HStack, Icon, Text, Box } from '@chakra-ui/react';
import { memo, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import isEqual from 'react-fast-compare';
import { TextStyle } from 'theme/types';
import { NavProps } from './types';
import { useTranslation } from 'react-i18next';

const NavItem = ({ label, icon, pathname }: NavProps) => {
  const location = useLocation();
  const { t } = useTranslation('nav');
  const isActive = useMemo(() => location.pathname === pathname, [location.pathname, pathname]);

  return (
    <Box
      as={Link}
      to={{ pathname }}
      w="full"
      _hover={{
        bg: 'hover',
        color: 'primary.500',
      }}
      _activeLink={{
        bg: 'hover',
        color: 'primary.500',
      }}
      color="nav"
      aria-current={isActive ? 'page' : undefined}
      borderRadius="md">
      <HStack spacing="2" px="12px" py="8px">
        {icon ? <Icon as={icon} fontSize="lg" /> : null}
        <Text
          textStyle={TextStyle.ParagraphSmall}
          ml={icon ? undefined : '26px'}
          fontWeight="medium"
          w="full">
          {t(label)}
        </Text>
      </HStack>
    </Box>
  );
};

export default memo(NavItem, isEqual);
